/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSkeletonText,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { Organization, OrganizationRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import SpinButton from '../../SpinButton'

import type { IntlShape } from 'react-intl'

import './organizationName.scss'

const messages = defineMessages({
  organizationName: {
    id: `organization.organization-name.organization-name-placeholder`,
    defaultMessage: `Organization name`,
  },
})

export interface Props {
  intl: IntlShape
  organization?: Organization
  fetchOrganizationIfNeeded: () => void
  updateOrganization: (updateRequest: OrganizationRequest) => Promise<any>
  fetchOrganizationRequest: AsyncRequestState
  updateOrganizationRequest: AsyncRequestState
}

type State = {
  editing: boolean
  editingName: string
}

class OrganizationName extends Component<Props, State> {
  state: State = {
    editing: false,
    editingName: ``,
  }

  componentDidMount(): void {
    this.props.fetchOrganizationIfNeeded()
  }

  render() {
    const { fetchOrganizationRequest, organization, updateOrganizationRequest } = this.props
    const { editing } = this.state

    if (fetchOrganizationRequest.error) {
      return <CuiAlert type='error'>{fetchOrganizationRequest.error}</CuiAlert>
    }

    if (!organization) {
      return (
        <div style={{ maxWidth: '250px' }}>
          <EuiSkeletonText lines={2} />
        </div>
      )
    }

    return (
      <PrivacySensitiveContainer>
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <EuiFlexGroup direction={'column'} gutterSize='s' responsive={false}>
              <EuiFlexItem>
                {editing
                  ? this.renderEditForm(organization)
                  : this.renderOrganizationName(organization)}
              </EuiFlexItem>

              <EuiFlexItem>{this.renderOrganizationId(organization)}</EuiFlexItem>

              {updateOrganizationRequest.error && (
                <EuiFlexItem>
                  <CuiAlert type='error'>{updateOrganizationRequest.error}</CuiAlert>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </PrivacySensitiveContainer>
    )
  }

  renderOrganizationName({ name: organizationName, id: organizationId }: Organization) {
    return (
      <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size='l'>
            <h2 data-test-id={'organization-name.organization-name'}>{organizationName}</h2>
          </EuiTitle>
        </EuiFlexItem>

        <PermissionsGate
          permissions={[
            {
              organizationId,
              type: 'organization',
              action: 'update',
            },
          ]}
        >
          <EuiFlexItem>
            <EuiButtonIcon
              aria-label={'edit-organization-name'}
              className={'edit-organization-name-button'}
              iconType={'pencil'}
              size={'s'}
              onClick={() => this.setState({ editing: true, editingName: organizationName })}
              data-test-id='organization-name.edit-button'
            />
          </EuiFlexItem>
        </PermissionsGate>
      </EuiFlexGroup>
    )
  }

  renderEditForm({ name: organizationName }: Organization) {
    const {
      intl: { formatMessage },
      updateOrganizationRequest,
    } = this.props

    const { editingName } = this.state

    const isDisabled = editingName === organizationName || editingName.trim() === ''

    return (
      <EuiFlexGroup gutterSize='s' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiFormRow>
            <EuiFieldText
              aria-label='organizationName'
              autoFocus={true}
              className={'edit-organization-name-input'}
              id='organizationName'
              onChange={(e) => this.setState({ editingName: (e.target as HTMLInputElement).value })}
              onKeyDown={({ key }) => {
                switch (key) {
                  case 'Enter':
                    this.saveChanges()
                    break

                  case 'Escape':
                    this.stopEditing()
                    break

                  default:
                }
              }}
              placeholder={formatMessage(messages.organizationName)}
              value={editingName}
            />
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiFormRow>
            <SpinButton
              color='primary'
              fill={true}
              onClick={() => this.saveChanges()}
              disabled={isDisabled}
              spin={updateOrganizationRequest.inProgress}
              data-test-id='organization-name.update-button'
            >
              <FormattedMessage
                id='organization.organization-name.update'
                defaultMessage='Update'
              />
            </SpinButton>
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiFormRow>
            <EuiButtonEmpty onClick={() => this.stopEditing()}>
              <FormattedMessage
                id='organization.organization-name.cancel'
                defaultMessage='Cancel'
              />
            </EuiButtonEmpty>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderOrganizationId(organization: Organization) {
    return (
      <EuiText size='s' color='subdued' data-test-id='organization-name.organization-id'>
        <FormattedMessage
          id='organization.organization-name.organization-id'
          defaultMessage='Organization ID: {organizationId}'
          values={{ organizationId: organization.id }}
        />
      </EuiText>
    )
  }

  saveChanges() {
    const { editingName } = this.state
    const { updateOrganization } = this.props

    updateOrganization({ name: editingName }).then(() => this.stopEditing())
  }

  stopEditing() {
    this.setState({ editing: false, editingName: `` })
  }
}

export default injectIntl(OrganizationName)
