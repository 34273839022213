/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { WithBillingDetailsProps } from '@modules/billing-lib/billingDetails/types'

import type { Moment } from 'moment'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'

export enum ViewBy {
  DAY = 'day',
  MONTH = 'month',
}

export enum ChartType {
  LINE = 'line',
  BAR = 'bar',
}

export enum ProductType {
  Deployments = 'deployments',
  Projects = 'projects',
}

export enum Solution {
  Security = 'security',
  Observability = 'observability',
  Search = 'elasticsearch',
}

export enum GroupBy {
  Product = 'product',
  Instance = 'instance',
}

export type BucketingStrategy = 'daily' | 'monthly'

export interface ChartCostsArgs {
  from: string
  to: string
  bucketingStrategy: BucketingStrategy
}

export interface Filters {
  selectedDeploymentId: string
  viewBy: ViewBy
  chartType: ChartType
  startDate: Moment
  endDate: Moment
}

export interface ConsumerProps {
  organizationId?: string
  selectedDeploymentId?: string
}

export interface StateProps {
  organizationId?: string
}
export type AllProps = StateProps & WithBillingDetailsProps & ConsumerProps & LDProps
