/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui'

import {
  useGetCostsInstancesV2,
  useGetCostsItemsV2,
  useGetUsageChartsDataV2,
} from '@modules/billing-lib/hooks'
import { processQueryParams } from '@modules/billing-api/utils'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import { ViewByToBucketingStrategy } from '@/components/User/BillingUsage/constants'
import CostsChart from '@/components/User/BillingUsage/components/CostsChart'
import ProductChart from '@/components/User/BillingUsage/components/ProductChart'
import MainFilters from '@/components/User/BillingUsage/components/Filters/MainFilters'
import SideFilters from '@/components/User/BillingUsage/components/Filters/SideFilters'

import ErrorCallout from '../components/ErrorCallout'
import { filtersReducer, Action } from '../components/Filters/filtersReducer'
import {
  stringifyFilters,
  parseFiltersQuery,
  getValidationError,
} from '../components/Filters/utils'
import { ProductType, GroupBy } from '../types'

import {
  getDeploymentInstances,
  getProjectInstances,
  filterProjectsInstancesBySolutions,
  getChartDataProductTypes,
  getDateRange,
} from './utils'
import DeploymentsList from './DeploymentsList'
import ProjectsList from './ProjectsList'
import ProductsList from './ProductsList'

import type { AllProps } from '@/components/User/BillingUsage/BillingUsageOverviewV2/types'
import type { FunctionComponent } from 'react'

const BillingUsageOverviewV2: FunctionComponent<AllProps> = ({
  organizationId,
  setInstanceName,
}) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(
    filtersReducer,
    parseFiltersQuery(history.location.search.slice(1)),
  )
  const areDatesValid = !getValidationError({
    startDate: state.startDate,
    endDate: state.endDate,
    viewBy: state.viewBy,
  })

  const costsInstancesQuery = useGetCostsInstancesV2(
    {
      pathParameters: {
        organization_id: organizationId,
      },
      queryParameters: processQueryParams({ ...getDateRange(state), include_names: true }),
    },
    {
      enabled: state.groupBy === GroupBy.Instance && areDatesValid,
    },
  )

  const costsChartDataQuery = useGetUsageChartsDataV2(
    {
      pathParameters: {
        organization_id: organizationId,
      },
      queryParameters: processQueryParams({
        ...getDateRange(state),
        instance_type: 'all',
        bucketing_strategy: ViewByToBucketingStrategy[state.viewBy],
      }),
    },
    {
      enabled: state.productTypes.length > 0 && areDatesValid,
    },
  )

  const costsItemsV2Query = useGetCostsItemsV2(
    {
      pathParameters: { organization_id: organizationId },
      queryParameters: processQueryParams(getDateRange(state)),
    },
    {
      enabled: state.groupBy === GroupBy.Product && areDatesValid,
    },
  )

  const instances = costsInstancesQuery.data?.instances || []
  const deploymentInstances = getDeploymentInstances(instances)
  const projectInstances = getProjectInstances(instances)
  const filteredProjectInstances = filterProjectsInstancesBySolutions(
    projectInstances,
    state.solutions,
  )
  const types = getChartDataProductTypes({
    deployments: deploymentInstances,
    projects: filteredProjectInstances,
    selectedProductTypes: state.productTypes,
  })
  const shouldShowServerlessFilters = true

  // Each time the filters change we want to update queryParams
  useEffect(() => {
    colorForInstances.reset()
    const queryString = stringifyFilters(state)
    history.push(`${history.location.pathname}?${queryString}`)
  }, [history, state])

  const onInstanceSelected = (instanceId: string, instanceName?: string) => {
    setInstanceName(instanceName)
    dispatch({ type: Action.SET_INSTANCE_ID, payload: { instanceId } })
  }

  const renderInstanceLists = () => {
    if (costsInstancesQuery.isError) {
      return <ErrorCallout retry={costsInstancesQuery.refetch} data-test-id='usage-table-error' />
    }

    return (
      <Fragment>
        {state.productTypes.includes(ProductType.Projects) && (
          <ProjectsList
            aggregated={types.length === 2}
            instances={filteredProjectInstances}
            isLoading={costsInstancesQuery.isLoading}
            onProjectSelected={onInstanceSelected}
            organizationId={organizationId}
            state={state}
          />
        )}

        <EuiSpacer size='xl' />

        {state.productTypes.includes(ProductType.Deployments) && (
          <DeploymentsList
            aggregated={types.length === 2}
            instances={deploymentInstances}
            isLoading={costsInstancesQuery.isLoading}
            onDeploymentSelected={onInstanceSelected}
            organizationId={organizationId}
            state={state}
          />
        )}
      </Fragment>
    )
  }

  const renderProductList = () => {
    if (costsItemsV2Query.isError) {
      return <ErrorCallout retry={costsItemsV2Query.refetch} data-test-id='usage-table-error' />
    }

    const products = costsItemsV2Query.data?.products || []

    return (
      <ProductsList products={products} filters={state} isLoading={costsItemsV2Query.isLoading} />
    )
  }

  const renderChart = () => {
    if (costsChartDataQuery.isError) {
      return <ErrorCallout data-test-id='costs-chart-error' retry={costsChartDataQuery.refetch} />
    }

    const isProductTypeSelected = !!state.productTypes.length
    const chartData = isProductTypeSelected ? costsChartDataQuery.data?.data : []

    const chartComponent = {
      [GroupBy.Product]: (
        <ProductChart
          data={chartData || []}
          filters={state}
          isLoading={costsChartDataQuery.isLoading}
        />
      ),
      [GroupBy.Instance]: (
        <CostsChart
          data={chartData || []}
          instanceTypes={types}
          filters={state}
          isLoading={costsChartDataQuery.isLoading}
        />
      ),
    }

    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='m'
        hasBorder={true}
        className='costs-chart-panel'
        data-test-id='costs-chart-panel'
      >
        {chartComponent[state.groupBy]}
      </EuiPanel>
    )
  }

  return (
    <Fragment>
      <EuiFlexGroup data-test-id='billing-usage'>
        <EuiFlexItem grow={10}>
          <MainFilters
            isLoading={costsInstancesQuery.isLoading}
            dispatch={dispatch}
            state={state}
            organizationId={organizationId}
            shouldShowServerlessFilters={shouldShowServerlessFilters}
          />
          <EuiSpacer size='m' />
          {renderChart()}
        </EuiFlexItem>

        <SideFilters
          isLoading={costsInstancesQuery.isLoading}
          dispatch={dispatch}
          state={state}
          shouldShowServerlessFilters={shouldShowServerlessFilters}
        />
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {
        {
          [GroupBy.Instance]: renderInstanceLists(),
          [GroupBy.Product]: renderProductList(),
        }[state.groupBy]
      }
    </Fragment>
  )
}

export default BillingUsageOverviewV2
