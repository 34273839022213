/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { MetricItem } from '@modules/autoops-api/types'

// We're knowingly dividing by 1024^3 (i.e. GiB) because that's how usage is billed. However, this amount will be shown
// using the GB unit like everywhere else (Billing page, Kibana), even though it is not the right unit.
// This is mainly for consistency's sake.
export const bytesToGigabytes = (bytes: number): number => {
  const NUMBER_OF_BYTES_IN_A_GB = 1024 * 1024 * 1024

  return bytes / NUMBER_OF_BYTES_IN_A_GB
}

export const numberFormat = (value: number, options?: { decimals: number }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: options?.decimals ?? 2,
    maximumFractionDigits: options?.decimals ?? 2,
  })

  return formatter.format(value)
}

export const generateCallout = (
  items: Array<MetricItem | undefined>,
): { type: 'info' | 'danger'; title: string; message?: string } => {
  const definedItems = items.filter((item) => item !== undefined)
  const errorItems = definedItems.filter((item) => item.error !== null)

  if (items.length === errorItems.length) {
    return { type: 'danger', title: 'Sorry, there was an error', message: items[0]?.error ?? '' }
  }

  return { type: 'info', title: 'Sorry, there was no data' }
}
