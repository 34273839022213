/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { useOrganizationId } from '@modules/permissions-lib/hooks'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import MetricsChart from '@modules/auto-ops-components/MetricsChart'
import type { ProjectType } from '@modules/ui-types/projects'

type MetricsProps = {
  projectId: string
  projectType: ProjectType
  region: string
}

const Metrics = ({ projectId, projectType, region }: MetricsProps) => {
  const { organizationId } = useOrganizationId()
  const [isFlagUsable, flags] = useFlagsWhenLoaded()
  const hasOrganization = organizationId !== undefined
  const isActivated = isFlagUsable && flags.showAutoOpsPerfomanceChart

  if (!hasOrganization || !isActivated) {
    return null
  }

  return (
    <MetricsChart
      projectId={projectId}
      projectType={projectType}
      organizationId={organizationId}
      region={region}
    />
  )
}

export default Metrics
