/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiEmptyPrompt,
  EuiHorizontalRule,
  EuiLoadingSpinner,
  EuiPageTemplate,
  EuiText,
} from '@elastic/eui'

import { useOrganizationIdp } from '@modules/security-idp-lib/hooks'
import Breadcrumbs from '@modules/cui/Breadcrumbs'

import { userAuthenticationCrumbs } from '@/lib/crumbBuilder'
import OrganizationNoRolesEmptyPrompt from '@/components/Organization/OrganizationNoRolesEmptyPrompt'

import UserProvidedSsoConfig from './UserProvidedSsoConfig'
import ElasticProvidedSsoConfig from './ElasticProvidedSsoConfig'
import LoginEnforcement from './LoginEnforcement'
import EditUserAuthenticationHeader from './EditUserAuthenticationHeader'

import type { Props } from './types'

const pageSectionStyle = {
  maxWidth: '951px',
  margin: '0 126px 0 126px',
  padding: '0',
  width: '100%',
}
const breadcrumbs = [...userAuthenticationCrumbs()]

export const EditUserAuthentication = (props: Props) => {
  const {
    organizationId,
    permissions: { isLoading: isLoadingPermissions, hasUpdateOrganizationIdp },
  } = props
  const { data: idpInfo, isLoading: isLoadingIdp } = useOrganizationIdp(organizationId)
  const isLoading = isLoadingPermissions || isLoadingIdp

  return (
    <EuiPageTemplate panelled={true} bottomBorder={'extended'}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <EuiPageTemplate.Header css={css({ padding: '0px' })}>
        <div css={pageSectionStyle}>
          <EditUserAuthenticationHeader
            idpConfiguration={idpInfo?.configuration}
            organizationId={organizationId}
            hasUpdateOrganizationIdp={hasUpdateOrganizationIdp}
          />
        </div>
      </EuiPageTemplate.Header>
      <EuiPageTemplate.Section css={{ ...pageSectionStyle, marginBottom: '190px' }}>
        {isLoading ? (
          <EuiEmptyPrompt
            icon={<EuiLoadingSpinner size='l' />}
            title={
              <FormattedMessage
                id='organization.security.edit-user-authentication.loading'
                defaultMessage='Loading user authentication information'
              />
            }
            color='subdued'
          />
        ) : (
          <div>
            <Explanation />
            <PageBody {...props} />
          </div>
        )}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

const PageBody = ({
  organizationId,
  permissions: {
    hasUpdateOrganizationIdp,
    hasViewOrganizationIdp,
    hasViewOrganization,
    hasUpdateOrganization,
  },
}: Props) => {
  const { data: idpInfo } = useOrganizationIdp(organizationId)

  const hasAccess =
    (hasViewOrganizationIdp && hasUpdateOrganizationIdp) ||
    (hasViewOrganization && hasUpdateOrganization)

  if (!hasAccess) {
    return <OrganizationNoRolesEmptyPrompt />
  }

  return (
    <div>
      {hasViewOrganization && hasUpdateOrganization && (
        <LoginEnforcement organizationId={organizationId} />
      )}
      {hasViewOrganizationIdp && hasUpdateOrganizationIdp && (
        <div>
          <EuiHorizontalRule size='full' />
          <UserProvidedSsoConfig idpResponse={idpInfo} organizationId={organizationId} />
          {idpInfo && <ElasticProvidedSsoConfig idpInfo={idpInfo} />}
        </div>
      )}
    </div>
  )
}

const Explanation = () => (
  <EuiText css={css({ p: { marginBottom: '0px' } })}>
    <p>
      <FormattedMessage
        id='organization.security.edit-user-authentication.explanation'
        defaultMessage='Users will be able to log in using SSO once your identity provider is successfully connected to Elastic Cloud.'
      />
    </p>
    <ol>
      <li>
        <FormattedMessage
          id='organization.security.edit-user-authentication.explanation-1'
          defaultMessage='Connect your Elastic Cloud organization to your identity provider.'
        />
      </li>
      <li>
        <FormattedMessage
          id='organization.security.edit-user-authentication.explanation-2'
          defaultMessage='Add and verify the domains that your users come from.'
        />
      </li>
      <li>
        <FormattedMessage
          id='organization.security.edit-user-authentication.explanation-3'
          defaultMessage='Once at least one domain is verified, define mappings to determine the default roles of your SSO users in Elastic Cloud.'
        />
      </li>
    </ol>
  </EuiText>
)
