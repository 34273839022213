/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import type { EuiTabbedContentTab } from '@elastic/eui'
import { EuiSpacer, EuiTitle } from '@elastic/eui'

import type { RoutableBreadcrumb } from '@modules/ui-types'
import { isPrepaidConsumptionDirectCustomer } from '@modules/billing-lib/billingDetails/utils'

import OrganizationEmptyPrompt from '@/components/Organization/OrganizationEmptyPrompt'
import BillingOverview from '@/components/User/BillingOverviewV2'
import BillingHistory from '@/components/User/BillingHistory'
import BillingSubscription from '@/components/User/BillingSubscription'
import BillingUsage from '@/components/User/BillingUsage'
import {
  billingOverviewCrumbs,
  billingHistoryCrumbs,
  costAnalysisCrumbs,
  billingCrumbs,
  billingSubscriptionCrumbs,
} from '@/lib/crumbBuilder'
import AccessDeniedPanel from '@/components/ErrorPages/AccessDenied/AccessDeniedPanel'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import PortalPage from '../PortalPage'

import type { ReactElement } from 'react'
import type { AllProps } from './types'

class Billing extends PureComponent<AllProps> {
  render(): ReactElement | null {
    const { isRouteFSTraced, organizationId, showOrganization, permissions } = this.props

    if (permissions.isLoading) {
      return <AppLoadingRoot />
    }

    if (showOrganization && !organizationId) {
      return (
        <PortalPage
          breadcrumbs={billingCrumbs()}
          className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
        >
          <OrganizationEmptyPrompt />
        </PortalPage>
      )
    }

    if (!permissions.hasBillingPermissions) {
      return <AccessDeniedPanel />
    }

    const tabbedContent = this.getTabbedContent()
    const breadcrumbs = this.getBreadcrumbs(tabbedContent.initialSelectedTab)

    return (
      <PortalPage
        breadcrumbs={breadcrumbs}
        className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
        contentHeader={
          <EuiTitle size='l'>
            <h2>
              <FormattedMessage id='cloud.billing.title' defaultMessage='Billing' />
            </h2>
          </EuiTitle>
        }
        tabbedContent={tabbedContent}
      />
    )
  }

  renderTabContent(content: ReactElement): ReactElement {
    return (
      <Fragment>
        <EuiSpacer size='l' />
        {content}
      </Fragment>
    )
  }

  getTabbedContent(): { tabs: EuiTabbedContentTab[]; initialSelectedTab: EuiTabbedContentTab } {
    const { location, profile, organizationId } = this.props

    const tabIdFromUrl = location.pathname.split('/').pop()

    const tabs: EuiTabbedContentTab[] = [
      {
        id: 'overview',
        name: 'Overview',
        content: this.renderTabContent(
          <BillingOverview data-test-id='billing-index-overview-link' />,
        ),
      },
      {
        id: 'usage',
        name: 'Usage',
        content: this.renderTabContent(<BillingUsage />),
      },
      {
        id: 'history',
        name: 'History',
        content: this.renderTabContent(
          <BillingHistory data-test-id='billing-index-history-link' profile={profile} />,
        ),
      },
    ]

    if (this.shouldShowSubscriptionTab(tabIdFromUrl)) {
      tabs.push({
        id: 'subscription',
        name: 'Subscription',
        content: this.renderTabContent(
          <BillingSubscription
            redirectToWhenDisabled='/billing/overview'
            organizationId={organizationId!}
          />,
        ),
      })
    }

    const initialSelectedTab = tabs.find(({ id }) => id === tabIdFromUrl) || tabs[0]!

    return { tabs, initialSelectedTab }
  }

  getBreadcrumbs(initialSelectedTab: EuiTabbedContentTab): RoutableBreadcrumb[] {
    const crumbs = {
      overview: billingOverviewCrumbs,
      usage: costAnalysisCrumbs,
      history: billingHistoryCrumbs,
      subscription: billingSubscriptionCrumbs,
    }

    return [...billingCrumbs(), ...crumbs[initialSelectedTab.id]()]
  }

  shouldShowSubscriptionTab(currentTabId: string | undefined) {
    const { billingDetails } = this.props

    // if the tab is currently selected for whatever reason we have no
    // other option but to show it. Child components might perform a
    // redirect if the user shouldn't see this tab.
    if (currentTabId === 'subscription') {
      return true
    }

    // for now, only show the tab to prepaid consumption users, even if the flag is enabled
    if (billingDetails.data && isPrepaidConsumptionDirectCustomer(billingDetails.data)) {
      return true
    }

    return false
  }
}

export default Billing
