/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGrid, EuiFlexItem } from '@elastic/eui'

import type { CostsOverview } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/utils'

import MonthToDateUsage from '../RatePanel/MonthToDateUsage'
import RatePanel from '../RatePanel'

import BalancePanel from './BalancePanel'

import type { FunctionComponent } from 'react'

interface Props {
  profile: UserProfile
  accountCostOverview: CostsOverview
}

const CostAnalysisWhenSubscribed: FunctionComponent<Props> = ({ accountCostOverview, profile }) => {
  const { balance } = accountCostOverview
  const isPrepaidConsumptionUser = isPrepaidConsumptionCustomer(profile)

  // TODO: this should be better defined and come from the API, but until then
  // we follow the logic described [here][1], depending on the response from costs:
  //
  // - If it returns a balance.line_items it's prepaid consumption
  // - If it doesn't but returns some other balance info, it's A+O
  // - If it contains nothing, we should hide the section
  //
  // [1]: https://github.com/elastic/operations-eng/issues/15057#issuecomment-919610147
  const shouldShowBalancePanel = Boolean(balance?.line_items.length)
  const shouldShowRatePanel =
    !shouldShowBalancePanel && Boolean(balance?.available || balance?.remaining)

  return (
    <EuiFlexGrid columns={3} data-test-id='cost-analysis-subscribed-user'>
      <EuiFlexItem>
        <MonthToDateUsage profile={profile} />
      </EuiFlexItem>

      {shouldShowRatePanel && (
        <EuiFlexItem>
          <RatePanel
            isPrepaidConsumptionUser={isPrepaidConsumptionUser}
            data-test-id='cost-analysis.credits-this-month'
            description={{
              text: (
                <FormattedMessage
                  id='cost-analysis.credits-this-month'
                  defaultMessage='Credits this month'
                />
              ),
            }}
            info={[
              {
                text: (
                  <FormattedMessage
                    id='cost-analysis.monthly-credit-deduction-info'
                    defaultMessage='This will be deducted from your bill this month'
                  />
                ),
              },
            ]}
            rate={balance?.available || 0}
            color='success'
          />
        </EuiFlexItem>
      )}

      {shouldShowBalancePanel && balance && (
        <EuiFlexItem>
          <BalancePanel isPrepaidConsumptionUser={isPrepaidConsumptionUser} balance={balance} />
        </EuiFlexItem>
      )}
    </EuiFlexGrid>
  )
}

export default CostAnalysisWhenSubscribed
