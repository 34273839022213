/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useGetRoleMappingsQuery } from '../../../../modules/security-idp-lib/hooks'
import RoleMappingsTable from '../RoleMappingsTable'

import type { Props } from './types'

const RoleMappings = (props: Props) => {
  const {
    organizationId,
    permissions: {
      isLoading: isLoadingPermissions,
      hasViewRoleMappingPermission,
      hasUpdateRoleMappingPermission,
      hasDeleteRoleMappingPermission,
    },
  } = props
  const { data, isLoading: isLoadingRoleMappings } = useGetRoleMappingsQuery(organizationId)
  const roleMappings = data?.mappings
  const isLoading = isLoadingPermissions || isLoadingRoleMappings

  if (!isLoading && !hasViewRoleMappingPermission) {
    return null
  }

  const hasNoRoleMappings = !isLoading && roleMappings?.length === 0

  return (
    <EuiPanel paddingSize='xl'>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexGroup direction='column' style={{ gap: '0px' }}>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage
                id='organization.security.role-mappings.title-role-mappings'
                defaultMessage='Role mappings'
              />
            </h2>
          </EuiTitle>
          {!hasNoRoleMappings && (
            <div>
              <EuiSpacer size='s' />
              <EuiText color='subdued' size='s'>
                <FormattedMessage
                  id='organization.security.role-mappings.description-learn-more'
                  defaultMessage='Define the roles of users accessing this organization from your identity provider.'
                />
              </EuiText>
            </div>
          )}
        </EuiFlexGroup>
        {!hasNoRoleMappings && (
          <EuiButton disabled={!hasUpdateRoleMappingPermission}>Create role mapping</EuiButton>
        )}
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      {hasNoRoleMappings ? (
        <NoRoleMappings />
      ) : (
        <RoleMappingsTable
          roleMappings={roleMappings}
          isLoading={isLoading}
          hasDeleteRoleMappingPermission={hasDeleteRoleMappingPermission}
          hasUpdateRoleMappingPermission={hasUpdateRoleMappingPermission}
        />
      )}
    </EuiPanel>
  )
}

const NoRoleMappings = () => (
  <EuiFlexGroup className='no-data-flex-group'>
    <EuiFlexItem className='no-data-left-panel'>
      <Description />
    </EuiFlexItem>
    <EuiFlexItem className='no-data-right-panel'>
      <EuiText>
        <EuiEmptyPrompt
          className='no-data-prompt'
          color='subdued'
          title={
            <h4>
              <FormattedMessage
                id='organization.security.role-mappings.title-create-your-first-role-mapping'
                defaultMessage='Create your first role mapping'
              />
            </h4>
          }
          body={
            <p>
              <FormattedMessage
                id='organization.security.role-mappings.description'
                defaultMessage='Role mappings control which roles are assigned to your users.'
              />
            </p>
          }
          actions={
            <EuiButton color='primary' fill={true}>
              <FormattedMessage
                id='organization.security.role-mappings.btn-create-role-mappings'
                defaultMessage='Create role mapping'
              />
            </EuiButton>
          }
        />
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)

const Description = () => (
  <EuiText color='subdued'>
    <FormattedMessage
      id='organization.security.role-mappings.description-learn-more'
      defaultMessage='Define the roles of users accessing this organization from your identity provider.'
    />
  </EuiText>
)

export default RoleMappings
