/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { useParams } from 'react-router'

import { EuiSkeletonLoading } from '@elastic/eui'

import { isProjectType } from '@modules/project-creation-lib/types'
import { useListRegionsQuery } from '@modules/cluster-user-lib/hooks'
import type { UrlParams } from '@modules/project-creation-lib/types'
import { projectCreationCrumbs } from '@modules/project-creation-lib/breadcrumbs'
import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'

import ProjectCreation from './ProjectCreation'
import { getSolutionContent } from './messages'
import LoadingProjectCreation from './LoadingProjectCreation'

import type { FunctionComponent } from 'react'

const ProjectCreationProvider: FunctionComponent = () => {
  const { solution } = useParams<UrlParams>()
  const regionsQuery = useListRegionsQuery()
  const { data: regions, isLoading } = regionsQuery

  if (!isProjectType(solution)) {
    return null
  }

  const solutionContent = getSolutionContent(solution)

  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={projectCreationCrumbs(solution)} />

      <EuiSkeletonLoading
        isLoading={isLoading}
        loadedContent={
          <ProjectCreation solution={solutionContent} regions={regions ? regions : []} />
        }
        loadingContent={<LoadingProjectCreation />}
      />
    </Fragment>
  )
}

export default ProjectCreationProvider
