/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState, useMemo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiTabs,
  EuiTab,
  EuiSpacer,
  EuiText,
  EuiStat,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
  EuiAccordion,
} from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'

import messages from '../../messages'
import UsageStat from '../UsageStat'

import { ProductTab } from './types'

import type { FunctionComponent } from 'react'
import type { ProjectCosts } from './types'

interface Props {
  isLoading: boolean
  totalUsage: number
  costs: ProjectCosts
}

const FullyManagedProjects: FunctionComponent<Props> = ({ isLoading, costs, totalUsage }) => {
  const availableProjectCosts = Object.keys(costs)
  const [selectedTabId, setSelectedTabId] = useState(availableProjectCosts[0])

  const onSelectedTabChanged = (id: ProductTab) => {
    setSelectedTabId(id)
  }

  const renderUsageRates = useCallback(
    (rates = []) => (
      <EuiFlexGrid columns={2} gutterSize='m' responsive={false}>
        {rates.map(({ type, total_ecu }) => (
          <EuiFlexItem key={type}>
            <EuiStat
              isLoading={isLoading}
              title={
                <UsageStat
                  title={
                    <CuiElasticConsumptionUnits value={total_ecu} unit='none' withSymbol={false} />
                  }
                  subText={<FormattedMessage {...messages.ecuUnit} />}
                />
              }
              description={<EuiText size='s'>{type}</EuiText>}
              descriptionElement='div'
              titleSize='xs'
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    ),
    [isLoading],
  )

  const tabs = useMemo(
    () =>
      [
        {
          id: ProductTab.Security,
          name: <FormattedMessage {...messages.securityProduct} />,
          content: renderUsageRates(costs.security),
        },
        {
          id: ProductTab.Observability,
          name: <FormattedMessage {...messages.observabilityProduct} />,
          content: renderUsageRates(costs.observability),
        },
        {
          id: ProductTab.Elasticsearch,
          name: <FormattedMessage {...messages.searchProduct} />,
          content: renderUsageRates(costs.elasticsearch),
        },
      ].filter(({ id }) => availableProjectCosts.includes(id)),
    [
      availableProjectCosts,
      costs.elasticsearch,
      costs.observability,
      costs.security,
      renderUsageRates,
    ],
  )

  const renderTabs = () =>
    tabs.map((tab, index) => (
      <EuiTab
        key={index}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
      >
        {tab.name}
      </EuiTab>
    ))

  const selectedTabContent = useMemo(
    () => tabs.find((obj) => obj.id === selectedTabId)?.content,
    [selectedTabId, tabs],
  )

  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiAccordion
        id='project'
        initialIsOpen={true}
        extraAction={
          <EuiStat
            isLoading={isLoading}
            textAlign='right'
            title={
              <UsageStat
                title={
                  <CuiElasticConsumptionUnits value={totalUsage} unit='none' withSymbol={false} />
                }
                subText={<FormattedMessage {...messages.ecuUnit} />}
              />
            }
            description={
              <EuiText size='s'>
                <FormattedMessage {...messages.currentUsage} />
              </EuiText>
            }
            descriptionElement='div'
            titleSize='xs'
          />
        }
        buttonContent={
          <EuiText size='s'>
            <h4>
              <FormattedMessage {...messages.fullyManagedProjects} />
            </h4>
          </EuiText>
        }
      >
        <EuiSpacer size='m' />

        <EuiTabs>{renderTabs()}</EuiTabs>

        <EuiSpacer size='l' />

        {selectedTabContent}
      </EuiAccordion>
    </EuiPanel>
  )
}

export default FullyManagedProjects
