/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiIcon,
} from '@elastic/eui'

import SetupEmailDevice from '@modules/mfa-enforcement/EnableMfaPage/SetupEmailDevice'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'
import SetupAuthenticatorDevice from '@modules/mfa-enforcement/EnableMfaPage/SetupAuthenticatorDevice'

import RemoveMfa from './RemoveMfa'

import type { DeviceType } from './types'

const DeviceOption: React.FunctionComponent<{
  type: Exclude<DeviceType, 'SMS'>
}> = ({ type }) => {
  const [activeModal, setActiveModal] = useState<{
    operation: 'setup' | 'remove'
    type: DeviceType
  } | null>(null)

  const { data } = useGetSaasCurrentUserMfaDevicesQuery()

  const activeDevices = data?.mfa_devices.filter(({ status }) => status === 'ACTIVE') ?? []
  const [activeDevice] = activeDevices.filter(({ device_type }) => device_type === type)

  return (
    <Fragment>
      <EuiPanel paddingSize='l' hasBorder={true}>
        <EuiFlexGroup>
          <EuiFlexGroup direction='column' gutterSize='s'>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent='spaceEvenly' alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <h2>
                    <EuiText size='s'>
                      <strong>
                        {type === 'GOOGLE' && (
                          <FormattedMessage
                            id='mfa-management.authenticator.title'
                            defaultMessage='Authenticator'
                          />
                        )}
                        {type === 'EMAIL' && (
                          <FormattedMessage
                            id='mfa-management.email.title'
                            defaultMessage='Email'
                          />
                        )}
                      </strong>
                    </EuiText>
                  </h2>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size='s'>
                {type === 'GOOGLE' && (
                  <FormattedMessage
                    id='mfa-management.authenticator.description'
                    defaultMessage='Generate verification codes on your mobile device with your preferred security app'
                  />
                )}
                {type === 'EMAIL' && (
                  <FormattedMessage
                    id='mfa-management.email.description'
                    defaultMessage='Receive a verification code on your email'
                  />
                )}
              </EuiText>

              <EuiSpacer size='s' />
            </EuiFlexItem>

            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                {activeDevice !== undefined ? (
                  <EuiButton
                    size='s'
                    color='danger'
                    onClick={() => {
                      setActiveModal({ operation: 'remove', type })
                    }}
                  >
                    <FormattedMessage id='mfa-management.remove' defaultMessage='Remove' />
                  </EuiButton>
                ) : (
                  <EuiButton
                    size='s'
                    onClick={() => {
                      setActiveModal({ operation: 'setup', type })
                    }}
                  >
                    <FormattedMessage id='mfa-management.set-up' defaultMessage='Set up' />
                  </EuiButton>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>

          {activeDevice !== undefined && (
            <EuiFlexItem grow={false}>
              <EuiIcon type='checkInCircleFilled' size='m' />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      {activeModal?.operation === 'remove' &&
        activeModal.type === type &&
        activeDevice !== undefined && (
          <RemoveMfa
            device={activeDevice}
            activeDevices={activeDevices}
            closeModal={() => {
              setActiveModal(null)
            }}
          />
        )}

      {activeModal?.operation === 'setup' && activeModal.type === 'GOOGLE' && (
        <SetupAuthenticatorDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}

      {activeModal?.operation === 'setup' && activeModal.type === 'EMAIL' && (
        <SetupEmailDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}
    </Fragment>
  )
}

export default DeviceOption
