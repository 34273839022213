/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  // @ts-ignore
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiLink,
  EuiPopover,
  EuiPopoverTitle,
  EuiSpacer,
} from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import { supportPortalUrl } from '@/apps/userconsole/urls'

import ExternalLink from '../ExternalLink'

const messages = defineMessages({
  help: {
    id: 'chrome-header-help-title',
    defaultMessage: 'Help',
  },
  essHelp: {
    id: 'chrome-header-ess-help-title',
    defaultMessage: 'Support',
  },
  ecHealth: {
    id: 'chrome-header-ec-health-title',
    defaultMessage: 'Elastic Cloud health',
  },
  giveFeedback: {
    id: 'chrome-header.help-give-feedback-link',
    defaultMessage: 'Give feedback',
  },
})

const Help = (props) => {
  const { intl } = props
  const { formatMessage } = intl

  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  const feedbackUrl = 'https://www.elastic.co/kibana/feedback'
  return (
    <EuiPopover
      id='chrome-header-help-popover'
      ownFocus={true}
      button={
        <EuiHeaderSectionItemButton
          onClick={togglePopover}
          aria-label={formatMessage(messages.help)}
        >
          <EuiIcon type='help' />
        </EuiHeaderSectionItemButton>
      }
      anchorPosition='downLeft'
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
    >
      <EuiPopoverTitle>
        <FormattedMessage {...messages.help} />
      </EuiPopoverTitle>
      <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon type='logoElasticsearch' size='m' />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiLink
            href={supportPortalUrl}
            color='text'
            data-test-id='chrome-header-help-btn'
            target='_blank'
          >
            <FormattedMessage {...messages.essHelp} />
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
        <EuiFlexItem>
          <EuiLink
            href='https://status.elastic.co/'
            color='text'
            data-test-id='chrome-header-help-ec-health-link'
            target='_blank'
          >
            <FormattedMessage {...messages.ecHealth} />
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
        <EuiFlexItem grow={false}>
          <ExternalLink
            href={feedbackUrl}
            showExternalLinkIcon={true}
            color='text'
            data-test-id='chrome-header-help-give-feedback-link'
          >
            <FormattedMessage {...messages.giveFeedback} />
          </ExternalLink>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  )
}

export default injectIntl(Help)
