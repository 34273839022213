/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import Feature from '@modules/utils/feature'
import getProjectRoutes from '@modules/project-user-pages/routes'
import getOnboardingRoutes from '@modules/onboarding-combine-flow/routes'
import { getCreateResourceRoutes } from '@modules/project-creation-lib/routes'
import PricingPage from '@modules/pricing-list-components/PricingPage'
import OpenKibanaRedirectPage from '@modules/kibana-redirect/OpenKibanaRedirectPage'
import { MfaEnforcement } from '@modules/mfa-enforcement/MfaEnforcement'

import { hydrateRouteConfig, withRouteChain } from '@/lib/router'
import RedirectToDeploymentResource from '@/components/RedirectToDeploymentResource'
import { withLDWrapper } from '@/apps/userconsole/components/LaunchdarklyWrapper'
import ManageAccountTrustRelationship from '@/components/DeploymentTrustRelationships/ManageAccountTrustRelationship'
import CreateDeploymentTrustRelationship from '@/components/DeploymentTrustRelationships/CreateDeploymentTrustRelationship'
import ManageDirectTrustRelationship from '@/components/DeploymentTrustRelationships/ManageDirectTrustRelationship'
import {
  getSupportedSliderInstanceTypesWithoutEs,
  getSupportedSliderInstanceTypes,
} from '@/lib/sliders/support'
import { getSliderPrettyName } from '@/lib/sliders/messages'
import EditUserAuthentication from '@/components/Security/EditUserAuthentication'

import APIConsole from '../../components/APIConsole'
import ClusterSnapshots from '../../components/Cluster/Snapshots'
import Account from '../../components/Portal/components/Account'
import Billing from '../../components/Portal/components/Billing'
import PortalLandingPage from '../../components/Portal/components/PortalLandingPage'
import SnapshotDetails from '../../components/Cluster/Snapshots/SnapshotDetails'
import Deployment from '../../components/Deployment'
import DeploymentOverview from '../../components/Deployment/Overview'
import HealthStatus from '../../components/Deployment/HealthStatus'
import HealthStatusDetails from '../../components/Deployment/HealthStatusDetails'
import DeploymentGettingStarted from '../../components/Deployment/DeploymentGettingStarted'
import UserSettings from '../../components/UserSettings'
import UserAccountSettings from '../../components/Portal/components/UserAccountSettings'
import UserRegistration from '../../components/UserRegistration'
import ServerlessUserRegistration from '../../components/UserRegistration/ServerlessUserRegistration'
import ManageTrafficFilters from '../../components/ManageTrafficFilters'
import CreateStackDeploymentRoute from '../../components/StackDeploymentEditor/CreateStackDeploymentRoute'
import EditStackDeployment from '../../components/StackDeploymentEditor/EditStackDeployment'
import StackDeploymentsRoute from '../../components/StackDeploymentsRoute'
import StackDeploymentActivityRoute from '../../components/StackDeploymentActivityRoute'
import StackDeploymentSlider from '../../components/StackDeploymentSlider'
import Logout from '../../components/Logout'
import NotFound from '../../components/ErrorPages/NotFound'
import AccessDenied from '../../components/ErrorPages/AccessDenied'
import SsoErrors from '../../components/ErrorPages/SsoErrors'
import IlmMigration from '../../components/IlmMigration'
import DeploymentExtensions from '../../components/DeploymentExtensions'
import DeploymentExtensionsGate from '../../components/DeploymentExtensionsGate'
import DeploymentExtensionCreate from '../../components/DeploymentExtensionCreate'
import DeploymentExtensionEdit from '../../components/DeploymentExtensionEdit'
import { hasUserSettings } from '../../components/UserSettings/helpers'
import OAuthLogin from '../../components/Login/OAuthLogin'
import AzureLogin from '../../components/Login/AzureLogin'
import UserconsoleSso from '../../components/Sso'
import { isFeatureActivated, getConfigForKey } from '../../selectors'

import AppRoot from './components/AppRoot'
import AuthenticatedPageWrapper from './components/AuthenticatedPageWrapper'
import UserconsoleRootRoute from './components/UserconsoleRootRoute'
import DeploymentFeatures from './components/DeploymentFeatures'
import AccountDetails from './components/AccountDetails'
import ClusterOverview from './components/Cluster/Overview'
import LogsMetrics from './components/Deployment/Logs'
import TrustManagement from './components/TrustManagement'
import ConfirmEmailChange from './components/ConfirmEmailChange'
import FullStoryCookieNotice from './components/FullStoryCookieNotice'
import Tracking from './components/Tracking'
import ForgotPassword from './components/ForgotPassword'
import UserconsoleLogin from './components/Login'
import Support from './components/Support'
import PerformanceMetrics from './components/PerformanceMetrics'
import AccountSecurity from './components/AccountSecurity'
import StackDeploymentPricingRoute from './components/StackDeploymentPricingRoute'
import ResetPassword from './components/ResetPassword'
import CreatePassword from './components/CreatePassword'
import AcceptInvitation from './components/AcceptInvitation'
import MarketplaceSignupModalV2 from './components/PartnerSignup/AWSMarketplaceSignupModal/MarketplaceSignupModalV2'
import PartnerSignupLogin from './components/PartnerSignup/GCPMarketplaceSignup/PartnerSignupLogin'
import MarketplaceConversion from './components/PartnerSignup/MarketplaceConversion'
import { getHerokuRoutes, getHerokuRedirects } from './herokuRoutes'
import DeploymentSecurity from './components/DeploymentSecurity'
import MarketplaceRegistrationRedirect from './components/PartnerSignup/AWSMarketplaceSignupModal/MarketplaceRegistrationRedirect'
import {
  billingUrl,
  billingUsageUrl,
  billingOverviewUrl,
  billingHistoryUrl,
  setUpMfaUrl,
} from './urls'
import PartnerSignupRegistration from './components/PartnerSignup/GCPMarketplaceSignup/PartnerSignupRegistration'

import type { ReduxState } from '@/types/redux'
import type { RedirectConfig } from '@/lib/router'
import type { IntlShape } from 'react-intl'
import type { RouteConfig } from 'react-router-config'

const messages = defineMessages({
  deploymentActivityPageSlider: {
    id: `userconsole-route-titles.deployment-activity-page-slider`,
    defaultMessage: `{name} Activity`,
  },
  sliderManagePageTitle: {
    id: `userconsole-route-titles.deployment-manage-page-slider`,
    defaultMessage: `Manage {name}`,
  },
})

export function getRedirects({ state }: { state: ReduxState }): RedirectConfig[] {
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const isPortalFeatureEnabled = isFeatureActivated(state, Feature.cloudPortalEnabled)

  if (isHeroku) {
    return getHerokuRedirects()
  }

  return [
    ...getPortalRedirects(),
    ...getStackDeploymentRedirects(),
    ...getAccountRedirects({
      isPortalFeatureEnabled,
    }),
    ...getBillingRedirects(),
    ...getPluginRedirects(),
    ...getSupportRedirects(),
  ]

  function getPortalRedirects(): RedirectConfig[] {
    if (!isPortalFeatureEnabled || !hasUserSettings(state)) {
      return []
    }

    return [{ from: '/settings', to: '/user/settings' }]
  }

  function getSupportRedirects(): RedirectConfig[] {
    return [{ from: '/help', to: '/support' }]
  }

  function getStackDeploymentRedirects(): RedirectConfig[] {
    return [
      {
        from: '/region/:regionId/deployment/:deploymentId',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/edit',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/config',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/console',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/console',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/logs',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/logs-metrics',
      },
      {
        from: '/deployments/:deploymentId/elasticsearch/logs',
        to: '/deployments/:deploymentId/logs-metrics',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/snapshots',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/snapshots',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/snapshots/:snapshotName',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/snapshots/:snapshotName',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/kibana',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/kibana',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/kibana/edit',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/apm',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/apm',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/metrics',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/metrics',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/security',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/security',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/activity',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/activity',
      },

      ...getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
        from: `/region/:regionId/deployment/:deploymentId/activity/${sliderInstanceType}`,
        to: `/deployments/resolve/cluster/:regionId/:deploymentId/activity/${sliderInstanceType}`,
      })),

      {
        from: '/deployments/:deploymentId/monitoring',
        to: '/deployments/:deploymentId/health',
      },
    ]
  }

  function getPluginRedirects(): RedirectConfig[] {
    return [
      { from: '/plugins', to: '/extensions' },
      { from: '/plugins/create', to: '/extensions/create' },
      { from: '/plugins/:pluginId', to: '/extensions/:extensionId/edit' },
    ]
  }
}

export function getAccountRedirects({
  isPortalFeatureEnabled,
}: {
  isPortalFeatureEnabled: boolean
}): RedirectConfig[] {
  const accountRedirect = {
    from: '/account',
    to: '/account/members',
  }

  const accountActivityRedirect = {
    from: '/account/activity',
    to: billingUsageUrl(),
  }

  const portalRedirects = isPortalFeatureEnabled
    ? [
        { from: '/account/profile', to: '/user/settings' },
        { from: '/account/security', to: '/user/settings' },
      ]
    : []

  return [accountRedirect, accountActivityRedirect, ...portalRedirects]
}

function getBillingRedirects(): RedirectConfig[] {
  const accountRedirect = {
    from: billingUrl(),
    to: billingOverviewUrl(),
  }

  const newBillingUrlsRedirects = [
    { from: '/account/usage', to: billingUsageUrl() },
    { from: '/account/billing', to: billingOverviewUrl() },
    { from: '/account/billing-history', to: billingHistoryUrl() },
    { from: '/deployment-pricing-table', to: '/cloud-pricing-table' },
  ]

  return [accountRedirect, ...newBillingUrlsRedirects]
}

export function getRoutes({ intl, state }: { state: ReduxState; intl: IntlShape }): RouteConfig[] {
  const { formatMessage } = intl
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`

  if (isHeroku) {
    return getHerokuRoutes()
  }

  const showPrices = isFeatureActivated(state, Feature.showPrices)
  const showSecurityPage = isFeatureActivated(state, Feature.showSecurityPage)
  const isPortalFeatureEnabled = isFeatureActivated(state, Feature.cloudPortalEnabled)

  const routes: RouteConfig[] = [
    {
      component: AppRoot,
      routes: getRootRoutes(),
    },
  ]

  return routes.map(hydrateRouteConfig)

  function getRootRoutes(): RouteConfig[] {
    return [
      {
        path: '/login',
        component: withLDWrapper(UserconsoleLogin),
        title: 'Login',
      },
      {
        path: '/login/oauth',
        component: OAuthLogin,
        title: 'Login',
      },
      {
        path: '/login/azure',
        component: AzureLogin,
        title: 'Login',
      },
      {
        path: '/login/marketplace',
        component: PartnerSignupLogin,
        title: 'Marketplace Login',
      },
      {
        path: '/login/sso/:loginIdentifier?',
        component: UserconsoleSso,
        title: 'Login via SSO',
      },
      { path: '/logout', component: Logout, title: 'Logging out' },
      ...getAwsMoonWalkRoutes(),
      {
        component: Tracking,
        routes: getTrackedRoutes(),
      },
    ]
  }

  function getAwsMoonWalkRoutes() {
    return [
      {
        path: '/marketplace/registration/v2',
        component: MarketplaceSignupModalV2,
        title: 'Elastic Sign up',
      },
    ]
  }

  function getTrackedRoutes(): RouteConfig[] {
    const signupRoutes = [
      {
        path: '/registration',
        component: withLDWrapper(UserRegistration),
        title: 'Create your account',
      },
      {
        path: '/serverless-registration',
        component: withLDWrapper(ServerlessUserRegistration),
        title: 'Create your account',
      },
      {
        path: '/partner-signup',
        component: withLDWrapper(MarketplaceRegistrationRedirect),
        title: 'Sign up',
      },
      {
        path: '/marketplace-registration',
        component: PartnerSignupRegistration,
        title: 'Sign up',
      },
    ]

    return [
      ...signupRoutes,
      {
        path: '/account/reset-password',
        component: withLDWrapper(ResetPassword),
        title: 'Reset your password',
      },
      {
        path: '/account/verify-email',
        component: CreatePassword,
        title: 'Activate your account',
      },
      {
        path: '/account/accept-invite',
        component: AcceptInvitation,
        title: 'Accept email invitation',
      },
      {
        path: '/forgot',
        component: withLDWrapper(ForgotPassword),
        title: 'Forgot password',
      },
      {
        path: '/change-email',
        component: ConfirmEmailChange,
        title: 'Confirm email change',
      },

      { path: '/', component: UserconsoleRootRoute },

      {
        path: '/open-kibana/:resourceType/:id',
        component: OpenKibanaRedirectPage,
        title: 'Opening Kibana',
      },

      ...(showPrices
        ? [
            {
              path: '/pricing',
              component: FullStoryCookieNotice,
              routes: [
                {
                  path: '/pricing',
                  component: StackDeploymentPricingRoute,
                  title: 'Elasticsearch Service pricing calculator',
                },
              ],
            },
          ]
        : []),

      {
        path: '/cloud-pricing-table',
        component: withLDWrapper(PricingPage),
        title: 'Elastic Cloud Pricing Table',
      },
      {
        path: '/deployment-pricing-table',
        component: withLDWrapper(PricingPage),
        title: 'Elastic Cloud Pricing Table',
      },
      { path: '/errors/sso', component: SsoErrors, title: 'Error' },
      { path: '/access-denied', component: AccessDenied, title: 'Access Denied' },
      ...getLoggedInRoutes(),

      { component: NotFound, title: 'Not Found' },
    ]
  }

  function getTrackedLoggedInRoutes(): RouteConfig[] {
    const props = { isRouteFSTraced: true }
    const isServerlessEnabled = isFeatureActivated(state, Feature.serverless)

    const routes: RouteConfig[] = [
      ...getPortalRoutes(),

      {
        path: '/deployments',
        component: StackDeploymentsRoute,
        title: 'Deployments',
      },
      {
        path: '/deployments/create',
        component: CreateStackDeploymentRoute,
        title: 'Create Deployment',
      },

      ...getProjectRoutes(),
      ...getOnboardingRoutes(),
      ...getCreateResourceRoutes(isServerlessEnabled),
      ...getStackDeploymentRoutes(),
      ...getDeploymentFeaturesRoutes(),
      ...getUserSettingsRoutes(),
      ...getAccountRoutes(),

      { path: '/support', component: Support, title: 'Support' },
    ]

    return routes.map((route) => ({
      ...route,
      props,
    }))
  }

  function getLoggedInRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = [...getTrackedLoggedInRoutes()]

    return [{ path: '*', component: AuthenticatedPageWrapper, routes }]
  }

  function getDeploymentFeaturesRoutes(): RouteConfig[] {
    const featureRoutes: RouteConfig[] = [
      {
        path: '/deployment-features',
        component: DeploymentFeatures,
        title: 'Deployment features',
      },
      ...getExtensionsRoutes(),
    ]

    if (isFeatureActivated(state, Feature.trafficFiltering)) {
      featureRoutes.push({
        path: '/deployment-features/traffic-filters',
        component: ManageTrafficFilters,
        title: 'Traffic filters',
      })
    }

    if (isFeatureActivated(state, Feature.crossEnvCCSCCR)) {
      featureRoutes.push({
        path: '/deployment-features/trust-management',
        component: TrustManagement,
        title: 'Trust management',
      })
    }

    return featureRoutes
  }

  function getPortalRoutes(): RouteConfig[] {
    if (!isPortalFeatureEnabled) {
      return []
    }

    const routes = [
      {
        path: '/home',
        component: PortalLandingPage,
        props: { isRouteFSTraced: true },
        title: null,
      },
      {
        path: '/user/settings',
        component: UserAccountSettings,
        title: 'User Settings',
      },
      {
        path: '/billing/overview',
        component: Billing,
        props: { isRouteFSTraced: true },
        title: 'Overview',
      },
      {
        path: '/billing/usage',
        component: Billing,
        props: { isRouteFSTraced: true },
        title: 'Usage',
      },
      {
        path: '/billing/history',
        component: Billing,
        props: { isRouteFSTraced: true },
        title: 'History',
      },
      {
        path: '/account/contacts',
        component: Account,
        props: { isRouteFSTraced: true },
        title: 'Contacts',
      },
      {
        path: '/account/keys',
        component: Account,
        props: { isRouteFSTraced: true },
        title: 'API keys',
      },
      {
        path: '/account/idp',
        component: Account,
        props: { isRouteFSTraced: true },
        title: 'Security',
      },
      {
        path: '/account/idp/user-authentication',
        component: EditUserAuthentication,
        props: { isRouteFSTraced: true },
        title: 'User authentication',
      },
      {
        path: '/account/members',
        component: Account,
        props: { isRouteFSTraced: true },
        title: 'Members',
      },
      {
        path: '/marketplace-conversion',
        component: MarketplaceConversion,
        title: 'Marketplace conversion',
      },
      {
        path: '/billing/subscription',
        component: Billing,
        props: { isRouteFSTraced: true },
        title: 'Subscription',
      },
      {
        path: setUpMfaUrl(),
        component: MfaEnforcement,
        title: 'Please set up at least one multifactor',
      },
    ]

    return routes
  }

  function getStackDeploymentRoutes(): RouteConfig[] {
    const deploymentRoutes: RouteConfig[] = [
      {
        path: '/deployments/:deploymentId',
        component: DeploymentOverview,
        title: getDeploymentOverviewTitle,
      },
      {
        path: '/deployments/:deploymentId/getting-started',
        component: DeploymentGettingStarted,
        title: getDeploymentOverviewTitle,
      },
      {
        path: '/deployments/:deploymentId/edit',
        component: EditStackDeployment,
        title: 'Edit deployment',
      },
      {
        path: '/deployments/:deploymentId/health',
        component: HealthStatus,
        title: 'Health',
      },
      {
        path: '/deployments/:deploymentId/health/:issue',
        component: HealthStatusDetails,
        title: 'Health status',
      },
      {
        path: '/deployments/:deploymentId/:sliderInstanceType(elasticsearch)',
        component: ClusterOverview,
        title: 'Manage Elasticsearch',
      },
      {
        path: '/deployments/:deploymentId/elasticsearch/console',
        component: APIConsole,
        title: 'Deployment console',
      },
      {
        path: '/deployments/:deploymentId/logs-metrics',
        component: LogsMetrics,
        title: 'Logs and metrics',
      },
      {
        path: '/deployments/:deploymentId/elasticsearch/snapshots',
        component: ClusterSnapshots,
        title: 'Deployment snapshots',
      },
      {
        path: '/deployments/:deploymentId/elasticsearch/snapshots/:snapshotName',
        component: SnapshotDetails,
        title: 'Snapshot :snapshotName',
      },

      ...getStackDeploymentSliderRoutes(),

      {
        path: '/deployments/:deploymentId/activity',
        component: StackDeploymentActivityRoute,
        title: 'Deployment Activity',
      },

      ...getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
        path: `/deployments/:deploymentId/activity/${sliderInstanceType}`,
        component: StackDeploymentActivityRoute,
        title: formatMessage(messages.deploymentActivityPageSlider, {
          name: formatMessage(getSliderPrettyName({ sliderInstanceType })),
        }),
      })),

      {
        path: '/deployments/:deploymentId/metrics',
        component: PerformanceMetrics,
        title: 'Performance',
      },
      {
        path: '/deployments/:deploymentId/security',
        component: DeploymentSecurity,
        title: 'Security',
      },
      {
        path: '/deployments/:deploymentId/security/trust/create',
        component: CreateDeploymentTrustRelationship,
        title: 'Add trusted environment',
      },
      {
        path: '/deployments/:deploymentId/security/trust/accounts/:trustRelationshipId',
        component: ManageAccountTrustRelationship,
        title: 'Manage trust',
      },
      {
        path: '/deployments/:deploymentId/security/trust/accounts',
        component: ManageAccountTrustRelationship,
        title: 'Add trusted environment',
      },
      {
        path: '/deployments/:deploymentId/security/trust/direct/:trustRelationshipId',
        component: ManageDirectTrustRelationship,
        title: 'Manage trust',
      },
      {
        path: '/deployments/:deploymentId/security/trust/direct',
        component: ManageDirectTrustRelationship,
        title: 'Add trusted environment',
      },
      {
        path: '/deployments/:deploymentId/migrate-to-index-lifecycle-management',
        component: IlmMigration,
        title: 'Migrate to Index Lifecycle Management',
      },
    ]

    return [
      {
        path: '/deployments/resolve/:resourceType/:regionId/:resourceId',
        component: RedirectToDeploymentResource,
      },
      {
        path: '/deployments/resolve/:resourceType/:regionId/:resourceId/**',
        component: RedirectToDeploymentResource,
      },

      ...deploymentRoutes.map(withRouteChain(Deployment)),
    ]

    function getStackDeploymentSliderRoutes(): RouteConfig[] {
      return getSupportedSliderInstanceTypesWithoutEs().map((sliderInstanceType) => {
        const route: RouteConfig = {
          path: `/deployments/:deploymentId/:sliderInstanceType(${sliderInstanceType})`,
          title: formatMessage(messages.sliderManagePageTitle, {
            name: formatMessage(getSliderPrettyName({ sliderInstanceType })),
          }),
          component: StackDeploymentSlider,
        }

        return route
      })
    }
  }

  function getExtensionsRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = [
      {
        path: '/deployment-features/extensions',
        component: DeploymentExtensions,
        title: 'Deployment extensions',
      },
      {
        path: '/deployment-features/extensions/create',
        component: DeploymentExtensionCreate,
        title: 'Create deployment extension',
      },
      {
        path: '/deployment-features/extensions/:extensionId/edit',
        component: DeploymentExtensionEdit,
        title: 'Deployment extension (:extensionId)',
      },
    ]

    return routes.map(withRouteChain(DeploymentExtensionsGate))
  }

  function getUserSettingsRoutes(): RouteConfig[] {
    if (!hasUserSettings(state) || isPortalFeatureEnabled) {
      return []
    }

    return [
      {
        path: '/settings',
        component: UserSettings,
        title: 'User settings',
      },
    ]
  }

  function getAccountRoutes(): RouteConfig[] {
    // @ts-ignore
    return [...getRoutesWhenPortalFlagIsOff(), ...getRoutesWhenPortalFlagIsOffAndNotHiding()]

    function getRoutesWhenPortalFlagIsOff() {
      if (isPortalFeatureEnabled) {
        return []
      }

      return [
        {
          path: '/account/profile',
          component: AccountDetails,
          title: 'Account Profile',
        },
      ]
    }

    function getRoutesWhenPortalFlagIsOffAndNotHiding() {
      if (!showSecurityPage || isPortalFeatureEnabled) {
        return []
      }

      return [
        {
          path: '/account/security',
          component: AccountSecurity,
          title: 'Account Security',
        },
      ]
    }
  }
}

function getDeploymentOverviewTitle({ match: { params } }): string {
  const { deploymentId } = params
  const shortId = deploymentId.substr(0, 6)

  return `[${shortId}] Overview`
}
