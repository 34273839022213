/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

/* Some fields are in snake_case below in order to match with the
 * property names in API requests. */
const messages = defineMessages({
  address1: {
    id: `credit-card-modal.form.address1`,
    defaultMessage: `Address line 1`,
  },
  address2: {
    id: `credit-card-modal.form.address2`,
    defaultMessage: `Address line 2 (optional)`,
  },
  first_name: {
    id: `credit-card-modal.form.first-name`,
    defaultMessage: `First name`,
  },
  last_name: {
    id: `credit-card-modal.form.last-name`,
    defaultMessage: `Last name`,
  },
  fullName: {
    id: `billing-details.form.full-name`,
    defaultMessage: `Full name`,
  },
  email: {
    id: `billing-details.form.email`,
    defaultMessage: `Email`,
  },
  business: {
    id: `billing-details.form.business`,
    defaultMessage: `Industry`,
  },
  employees_number: {
    id: `billing-details.form.employees-number`,
    defaultMessage: `Number of employees`,
  },
  addTitle: {
    id: 'credit-card-modal.add-title',
    defaultMessage: 'Subscribe to Elastic Cloud',
  },
  editTitle: {
    id: 'credit-card-modal.edit-title',
    defaultMessage: 'Edit billing details',
  },
  zip: {
    id: 'credit-card-modal.zip',
    defaultMessage: 'ZIP or Postal Code',
  },
  city: {
    id: 'credit-card-modal.city',
    defaultMessage: 'City',
  },
  domain: {
    id: `credit-card-modal.form.domain`,
    defaultMessage: `Website`,
  },
  country: {
    id: 'credit-card-modal.country',
    defaultMessage: 'Country',
  },
  statePlaceholder: {
    id: `billing-details.form.statePlaceholder`,
    defaultMessage: `Select a country`,
  },
  optional: {
    id: `billing.form.optional`,
    defaultMessage: `(Optional)`,
  },
  company: {
    id: 'credit-card-modal.company',
    defaultMessage: 'Company or organization',
  },
  state: {
    id: 'credit-card-modal.state',
    defaultMessage: 'State / County / Province',
  },
  vat_number: {
    id: 'credit-card-modal.vat_number',
    defaultMessage: 'VAT # (optional)',
  },
  stepBillingContact: {
    id: `credit-card-modal.step-billing-contact`,
    defaultMessage: `Billing contact`,
  },
  stepBillingAddress: {
    id: `credit-card-modal.step-billing-address`,
    defaultMessage: `Billing address`,
  },
  stepPayment: {
    id: `credit-card-modal.step-payment`,
    defaultMessage: `Payment`,
  },
  submit: {
    id: `credit-card-modal.submit`,
    defaultMessage: `Save`,
  },
  confirmSubscriptionButton: {
    id: `credit-card-modal.confirm-subscription-button`,
    defaultMessage: `Confirm subscription`,
  },
  nextPage: {
    id: `credit-card-modal.next-page`,
    defaultMessage: `Next`,
  },
  prevPage: {
    id: `credit-card-modal.prev-page`,
    defaultMessage: `Previous`,
  },
  cancel: {
    id: `credit-card-modal.cancel`,
    defaultMessage: `Cancel`,
  },
  countryPlaceholder: {
    id: `credit-card-modal.countryPlaceholder`,
    defaultMessage: `Select state or county`,
  },
  fieldRequired: {
    id: `credit-card-modal.field-required`,
    defaultMessage: `Required`,
  },
  feedbackDomain: {
    id: `credit-card-modal.domain.invalid`,
    defaultMessage: `The website is invalid`,
  },
  feedbackEmail: {
    id: `billing-details.feedbackemail.invalid`,
    defaultMessage: `The email is invalid`,
  },
  changeSubscription: {
    id: `billing-details.change-subscription`,
    defaultMessage: `Change`,
  },
  saveSubscription: {
    id: `billing-details.save-subscription`,
    defaultMessage: `Save`,
  },
  cancelSubscription: {
    id: `billing-details.back`,
    defaultMessage: `Back`,
  },
  yourSubscriptionLevel: {
    id: `billing-details.your-subscription-level`,
    defaultMessage: `Your subscription level is:`,
  },
  changeSubscriptionButton: {
    id: `billing-details.change-subscription-button`,
    defaultMessage: `Change subscription`,
  },
  changeSubscriptionNoThanks: {
    id: `billing-details.change-subscription-cancel-button`,
    defaultMessage: `No, thanks`,
  },
  confirmSubscriptionTitle: {
    id: `billing-details.confirm-subscription.title`,
    defaultMessage: `Do you want to keep {subscriptionLevels} features?`,
  },
  confirmSubscriptionTitlePlatinum: {
    id: `billing-details.confirm-subscription.title-enterprise`,
    defaultMessage: `Are you sure?`,
  },
  confirmSubscriptionDescription: {
    id: `billing-details.confirm-subscription.description`,
    defaultMessage: `By selecting {level}, you will lose access to these features:`,
  },
  confirmSubscriptionRemoveFeatures: {
    id: `billing-details.confirm-subscription.remove-features`,
    defaultMessage: `You will be prompted to remove some features if you continue.`,
  },
  confirmSubscription: {
    id: `billing-details.confirm-subscription`,
    defaultMessage: `Yes, update subscription`,
  },
  noThanks: {
    id: `billing-details.no-thanks`,
    defaultMessage: `No thanks`,
  },
  machineLearning: {
    id: `platinum-features.machine-learning`,
    defaultMessage: `Machine learning with anomaly detection`,
  },
  customPlugins: {
    id: `gold-features.custom-plugins`,
    defaultMessage: `Custom plugins`,
  },
  graphExplortation: {
    id: `platinum-features.graph-exploration`,
    defaultMessage: `Graph exploration and analytics`,
  },
  superSupport: {
    id: `platinum-features.super-support`,
    defaultMessage: `24/7/365 support for up to 8 support contacts`,
  },
})

export default messages
