/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import {
  deleteDomainClaim,
  generateDomainVerificationCode,
  getDomainClaims,
  getOrganizationIdp,
  getRoleMappings,
  getOrganizationSsoFindIdp,
  updateOrganizationIdp,
  updateRoleMappings,
  verifyDomainClaim,
} from '@modules/security-idp-api'
import { queryClient } from '@modules/query'
import type { ApiErrorCollection } from '@modules/query/types'
import type { IdpConfiguration } from '@modules/security-idp-api/types'
import type { RoleMapping } from '@modules/cloud-api/v1/types'

const domainClaimsKey = ['organization', 'domains', 'domain-claims']
const idpKey = ['organization', 'idp']
const roleMappingKey = ['organization', 'role-mappings']

export const useGetDomainsQuery = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: domainClaimsKey,
    queryFn: () => getDomainClaims(organizationId as string),
  })

export const useGenerateDomainVerificationCode = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      generateDomainVerificationCode(organizationId, domainName),
  })

export const useVerifyDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      verifyDomainClaim(organizationId, domainName),
    onSuccess: () => {
      queryClient.invalidateQueries(domainClaimsKey)
    },
  })

export const useDeleteDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      deleteDomainClaim(organizationId, domainName),
    onSuccess: () => {
      queryClient.invalidateQueries(domainClaimsKey)
    },
  })

export const useOrganizationIdp = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: idpKey,
    queryFn: () => getOrganizationIdp(organizationId as string),
    refetchOnWindowFocus: false,
    retry: (failureCount, error: ApiErrorCollection) =>
      !!(error.statusCode && error.statusCode >= 500 && failureCount < 3), //Only retry 2 times if it is 5XX error
  })

export const useOrganizationIdpMutation = () =>
  useMutation({
    mutationFn: ({
      organizationId,
      idpConfiguration,
    }: {
      organizationId: string
      idpConfiguration: IdpConfiguration
    }) => updateOrganizationIdp({ organizationId, idpConfiguration }),
    onSuccess: async ({ configuration }) => {
      const previousIdpInfo = queryClient.getQueryData(idpKey)

      queryClient.setQueryData(idpKey, {
        ...(previousIdpInfo || {}),
        configuration: { ...configuration },
      })
      queryClient.invalidateQueries(idpKey)
    },
  })

export const useGetRoleMappingsQuery = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: roleMappingKey,
    queryFn: () => getRoleMappings(organizationId as string),
  })

export const useUpdateRoleMappings = () =>
  useMutation({
    mutationFn: ({
      organizationId,
      mappings,
    }: {
      organizationId: string
      mappings: RoleMapping[]
    }) => updateRoleMappings({ organizationId, mappings }),
    onSuccess: () => {
      queryClient.invalidateQueries(roleMappingKey)
    },
  })
export const useGetOrganizationSsoFindIdp = (email: string) =>
  useQuery({
    enabled: !!email,
    queryKey: ['organization', 'sso', 'find-idp', email],
    queryFn: () => getOrganizationSsoFindIdp(email),
  })
