/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export const FETCH_ACCOUNT_ACTIVITY: 'FETCH_ACCOUNT_ACTIVITY' = `FETCH_ACCOUNT_ACTIVITY`
export const FETCH_ACCOUNT_DETAILS: 'FETCH_ACCOUNT_DETAILS' = `FETCH_ACCOUNT_DETAILS`
export const FETCH_PROFILE: 'FETCH_PROFILE' = `FETCH_PROFILE`
export const UPDATE_ACCOUNT_EMAIL: 'UPDATE_ACCOUNT_EMAIL' = `UPDATE_ACCOUNT_EMAIL`
export const UPDATE_ACCOUNT_PASSWORD: 'UPDATE_ACCOUNT_PASSWORD' = `UPDATE_ACCOUNT_PASSWORD`
export const UPDATE_ACCOUNT_DETAILS: 'UPDATE_ACCOUNT_DETAILS' = `UPDATE_ACCOUNT_DETAILS`
export const FETCH_METRICS: 'FETCH_METRICS' = `FETCH_METRICS`
export const FETCH_LOGS: 'FETCH_LOGS' = `FETCH_LOGS`
export const SIGN_UP_GCP_USER: 'SIGN_UP_GCP_USER' = `SIGN_UP_GCP_USER`
export const SIGN_UP_AWS_USER: 'SIGN_UP_AWS_USER' = `SIGN_UP_AWS_USER`
export const SIGN_UP_AZURE_USER: 'SIGN_UP_AZURE_USER' = `SIGN_UP_AZURE_USER`
export const VERIFY_ACCOUNT: 'VERIFY_ACCOUNT' = `VERIFY_ACCOUNT`
export const SET_INITIAL_ACCOUNT_PASSWORD: 'SET_INITIAL_ACCOUNT_PASSWORD' = `SET_INITIAL_ACCOUNT_PASSWORD`
export const RESEND_EMAIL_VERIFICATION_LINK: 'RESEND_EMAIL_VERIFICATION_LINK' = `RESEND_EMAIL_VERIFICATION_LINK`
export const RESET_PASSWORD: 'RESET_PASSWORD' = `RESET_PASSWORD`
export const SAVE_BILLING_DETAILS: 'SAVE_BILLING_DETAILS' = `SAVE_BILLING_DETAILS`
export const RECURLY_TOKEN_REQUESTED: 'RECURLY_TOKEN_REQUESTED' = `RECURLY_TOKEN_REQUESTED`
export const RECURLY_REQUEST_REJECTED: 'RECURLY_REQUEST_REJECTED' = `RECURLY_REQUEST_REJECTED`
export const RECURLY_TOKEN_RECEIVED: 'RECURLY_TOKEN_RECEIVED' = `RECURLY_TOKEN_RECEIVED`
export const CONFIRM_EMAIL_CHANGE: 'CONFIRM_EMAIL_CHANGE' = `CONFIRM_EMAIL_CHANGE`
export const CLEAR_EMAIL_CHANGE_CONFIRMATION: 'CLEAR_EMAIL_CHANGE_CONFIRMATION' = `CLEAR_EMAIL_CHANGE_CONFIRMATION`
export const FETCH_MFA_STATUS: 'FETCH_MFA_STATUS' = `FETCH_MFA_STATUS`
export const ENABLE_MFA: 'ENABLE_MFA' = `ENABLE_MFA`
export const DISABLE_MFA: 'DISABLE_MFA' = `DISABLE_MFA`
export const FETCH_MFA_DEVICES: 'FETCH_MFA_DEVICES' = `FETCH_MFA_DEVICES`
export const ENROLL_MFA_DEVICE: 'ENROLL_MFA_DEVICE' = `ENROLL_MFA_DEVICE`
export const ACTIVATE_MFA_DEVICE: 'ACTIVATE_MFA_DEVICE' = `ACTIVATE_MFA_DEVICE`
export const REMOVE_MFA_DEVICE: 'REMOVE_MFA_DEVICE' = `REMOVE_MFA_DEVICE`
export const FETCH_BASE_PRICES: 'FETCH_BASE_PRICES' = `FETCH_BASE_PRICES`
export const CREATE_SUPPORT_CASE: 'CREATE_SUPPORT_CASE' = `CREATE_SUPPORT_CASE`
export const FETCH_JWT_TOKEN: 'FETCH_JWT_TOKEN' = 'FETCH_JWT_TOKEN'
export const UPDATE_BILLING_SUBSCRIPTION_LEVEL: 'UPDATE_BILLING_SUBSCRIPTION_LEVEL' = `UPDATE_BILLING_SUBSCRIPTION_LEVEL`
export const FETCH_USAGE_DETAILS: 'FETCH_USAGE_DETAILS' = `FETCH_USAGE_DETAILS`
export const FETCH_DRIFT_JWT: 'FETCH_DRIFT_JWT' = 'FETCH_DRIFT_JWT'
export const GET_PAYMENT_DETAILS: 'GET_PAYMENT_DETAILS' = 'GET_PAYMENT_DETAILS'
