/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiPopoverTitle } from '@elastic/eui'

import { getActiveItems } from '@modules/billing-lib/utils'
import type { SimplifiedLineItem } from '@modules/cloud-api/v1/types'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'

import BalanceLineItems from './BalanceLineItems'

import type { ReactElement } from 'react'

import './balanceLineItemsPopover.scss'

export interface Props {
  lineItems: SimplifiedLineItem[]
  dateReference?: Date
}

class BalanceLineItemsPopover extends PureComponent<Props> {
  render(): ReactElement | null {
    const { lineItems, dateReference } = this.props

    if (!lineItems.length) {
      return null
    }

    const activeLineItems = getActiveItems(lineItems, dateReference || new Date())

    return (
      <CuiToggleablePopoverForClassComp toggleButton={this.renderPopoverButton}>
        <EuiPopoverTitle>
          <FormattedMessage
            id='cost-analysis.popover-info.title'
            defaultMessage='Total available balance by order line'
          />
        </EuiPopoverTitle>
        <BalanceLineItems items={activeLineItems} />
      </CuiToggleablePopoverForClassComp>
    )
  }

  renderPopoverButton(togglePopover: () => void): ReactElement {
    return (
      <EuiLink onClick={togglePopover} data-test-id='open-popover-button'>
        <FormattedMessage id='cost-analysis.popover-info' defaultMessage='Info' />
      </EuiLink>
    )
  }
}

export default BalanceLineItemsPopover
