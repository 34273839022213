/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  EuiTitle,
  withEuiTheme,
} from '@elastic/eui'

import type { DeploymentHealth, ResourceHealth } from '@modules/cloud-api/v1/types'

import DocLink from '@/components/DocLink'

import type { FunctionComponent } from 'react'

type Props = {
  health: DeploymentHealth | null
  theme: WithEuiThemeProps['theme']
}

const HEALTHY_DEPLOYMENT_STATUS = `Green`

type HealthyServices = Record<string, string[]>

const getHealthyServices = (health: DeploymentHealth | null): HealthyServices => {
  if (!health) {
    return {}
  }

  return Object.entries(health).reduce((acc, [key, value]) => {
    const resourceHealth = value as ResourceHealth

    const indicators = resourceHealth.indicators

    if (indicators !== undefined) {
      return {
        ...acc,
        [key]: Object.keys(indicators).filter(
          (indicatorKey) => indicators[indicatorKey]?.status === HEALTHY_DEPLOYMENT_STATUS,
        ),
      }
    }

    if (resourceHealth.status === HEALTHY_DEPLOYMENT_STATUS) {
      return {
        ...acc,
        [key]: [], // no indicators
      }
    }

    return acc
  }, {} as HealthyServices)
}

const mapServiceNameToLabel = (serviceName: string) => {
  switch (serviceName) {
    case `kibana`:
      return <FormattedMessage id='healthy-deployment.kibana' defaultMessage='Kibana' />

    case `apm`:
      return <FormattedMessage id='healthy-deployment.apm' defaultMessage='APM' />

    case `enterprise_search`:
      return (
        <FormattedMessage
          id='healthy-deployment.enterprise-search'
          defaultMessage='Enterprise Search'
        />
      )

    case `master_is_stable`:
      return (
        <FormattedMessage id='healthy-deployment.master-is-stable' defaultMessage='Master health' />
      )

    case `repository_integrity`:
      return (
        <FormattedMessage
          id='healthy-deployment.repository-integrity'
          defaultMessage='Repository integrity'
        />
      )

    case `ilm`:
      return (
        <FormattedMessage
          id='healthy-deployment.ilm'
          defaultMessage='Index Lifecycle Management (ILM)'
        />
      )

    case `slm`:
      return (
        <FormattedMessage
          id='healthy-deployment.slm'
          defaultMessage='Snapshot Lifecycle Management (SLM)'
        />
      )

    case `shards_availability`:
      return (
        <FormattedMessage
          id='healthy-deployment.shard-availability'
          defaultMessage='Shard availability'
        />
      )

    case `integration_server`:
      return (
        <FormattedMessage
          id='healthy-deployment.integration-server'
          defaultMessage='Integration server'
        />
      )

    case `disk`:
      return <FormattedMessage id='healthy-deployment.disk' defaultMessage='Disk usage' />

    default:
      // Capitalize the first letter and remove the `_` if any
      return capitalize(serviceName.replace(/_/g, ' '))
  }
}

const sortServicesByOrderOfImportance = (services: HealthyServices): Array<[string, string[]]> => {
  const orderOfImportance = {
    kibana: 1,
    apm: 2,
    enterprise_search: 3,
    integrations_server: 4,
    elasticsearch: 5,
    default: Number.MAX_SAFE_INTEGER,
  }

  const elasticsearch = {
    master_is_stable: 1,
    disk: 2,
    repository_integrity: 3,
    ilm: 4,
    slm: 5,
    shards_availability: 6,
  }

  const sortElasticsearchServices = (elasticsearchServices: string[]) =>
    elasticsearchServices.sort((a, b) => {
      const aOrder = elasticsearch[a] || orderOfImportance.default
      const bOrder = elasticsearch[b] || orderOfImportance.default

      return aOrder - bOrder
    })

  if (services.elasticsearch) {
    services.elasticsearch = sortElasticsearchServices(services.elasticsearch)
  }

  return Object.entries(services).sort(([a], [b]) => {
    const aOrder = orderOfImportance[a] || orderOfImportance.default
    const bOrder = orderOfImportance[b] || orderOfImportance.default

    return aOrder - bOrder
  })
}

const HealthyDeploymentComponent: FunctionComponent<Props> = ({ health, theme }) => {
  const healthyServices = sortServicesByOrderOfImportance(getHealthyServices(health))

  return (
    <EuiEmptyPrompt
      data-test-subj='health-status-healthy-deployment'
      title={
        <h2>
          <FormattedMessage
            id='deployment-health-status.is-healthy-title'
            defaultMessage='Your deployment is healthy'
          />
        </h2>
      }
      body={
        <EuiFlexGroup
          direction='column'
          gutterSize='s'
          data-test-subj='healthy-deployment-services-list'
        >
          {healthyServices.map(([resourceKind, indicators]) => {
            // If there are indicators, render as nested list
            if (indicators.length > 0) {
              return (
                <div key={resourceKind}>
                  <EuiFlexItem>
                    <HealthyDeploymentListItem name={resourceKind} />
                  </EuiFlexItem>
                  <EuiFlexGroup
                    css={{ marginLeft: theme.euiTheme.size.xl, marginTop: theme.euiTheme.size.s }}
                    data-test-subj='healthy-deployment-services-nested-list'
                  >
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup direction='column' gutterSize='s'>
                        {indicators.map((indicator) => (
                          <EuiFlexItem key={indicator}>
                            <HealthyDeploymentListItem name={indicator} />
                          </EuiFlexItem>
                        ))}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </div>
              )
            }

            return (
              <EuiFlexItem key={resourceKind}>
                <HealthyDeploymentListItem name={resourceKind} />
              </EuiFlexItem>
            )
          })}
        </EuiFlexGroup>
      }
      footer={
        <React.Fragment>
          <EuiTitle size='xxs'>
            <h3>
              <FormattedMessage
                id='deployment-health-status.is-healthy-footer-title'
                defaultMessage='Want to learn more?'
              />
            </h3>
          </EuiTitle>
          <DocLink link={'keepingYourDeploymentHealthy'} showExternalLinkIcon={true}>
            <FormattedMessage
              id='deployment-health-status.is-healthy-footer-link'
              defaultMessage='Read documentation'
            />
          </DocLink>
        </React.Fragment>
      }
    />
  )
}

export const HealthyDeployment = withEuiTheme(HealthyDeploymentComponent)

const HealthyDeploymentListItem: FunctionComponent<{ name: string }> = ({ name }) => (
  <EuiFlexGroup gutterSize='s'>
    <EuiFlexItem grow={false}>
      <EuiIcon type={'checkInCircleFilled'} color={'success'} size={'l'} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText>
        <p>{mapServiceNameToLabel(name)}</p>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)
