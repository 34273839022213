/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import moment from 'moment'
import { PureComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { Summary } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiBeveledIcon } from '@modules/cui/BeveledIcon'
import { getThemeColors, getThemeVariables } from '@modules/cui/theme'

import ExternalLink from '../../../ExternalLink'
import PortalTile from '../PortalTile'

export interface Props {
  fetchCloudStatus: () => Promise<any>
  fetchCloudStatusRequest: AsyncRequestState
  cloudStatus: Summary
}

class CloudStatusTile extends PureComponent<Props> {
  componentDidMount() {
    const { fetchCloudStatus } = this.props
    fetchCloudStatus()
  }

  render() {
    const { cloudStatus, fetchCloudStatusRequest } = this.props
    const { status, incidents } = cloudStatus

    const { euiSizeS, euiSizeM } = getThemeVariables()
    const { euiColorLightShade } = getThemeColors()

    if (!status.indicator || status.indicator === 'none') {
      return null
    }

    return (
      <Fragment>
        <PortalTile>
          <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
            <EuiFlexItem grow={false}>{this.getCloudStatusIcon(status)}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiTitle size='xs'>
                <h2>
                  <ExternalLink
                    showExternalLinkIcon={false}
                    color='text'
                    data-test-id='portal-cloud-status-tile-title-link'
                    href='https://cloud-status.elastic.co/'
                  >
                    <strong>
                      <FormattedMessage
                        id='cloud-portal.platform-status-title'
                        defaultMessage='Cloud status'
                      />
                    </strong>
                  </ExternalLink>
                </h2>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='m' />

          {!status.indicator && fetchCloudStatusRequest.inProgress ? (
            <Fragment>
              <EuiSpacer size='s' />
              <EuiSkeletonText lines={1} />
            </Fragment>
          ) : (
            <div css={css({ paddingLeft: euiSizeS })}>
              <EuiDescriptionList>
                <EuiDescriptionListTitle
                  css={incidents.length === 0 && css({ fontWeight: 'normal' })}
                >
                  <EuiHealth color={this.getHealthIndicator(status.indicator)}>
                    {this.toSentenceCase(status.description)}
                  </EuiHealth>
                </EuiDescriptionListTitle>
                <div
                  css={css({
                    marginLeft: euiSizeS,
                    paddingLeft: euiSizeM,
                    borderLeft: `1px solid ${euiColorLightShade}`,
                  })}
                >
                  {this.renderStatusIncidents(incidents)}
                </div>
              </EuiDescriptionList>
            </div>
          )}
        </PortalTile>

        <EuiSpacer size='l' />
      </Fragment>
    )
  }

  renderStatusIncidents(incidents) {
    return incidents.map((incident, index) => {
      const parsedDate = this.parseDate(incident.created_at)
      return (
        <Fragment key={incident.id}>
          {index > 0 && <EuiSpacer size='m' />}
          <EuiDescriptionListDescription>
            <ExternalLink
              href={incident.url}
              data-test-id={`portal-cloud-status-incident-item-link-${index}`}
            >
              {incident.name}
            </ExternalLink>
            <EuiSpacer size='xs' />
            <EuiText color='subdued' size='xs'>
              <FormattedMessage
                id='cloud-platform-status-timestamp'
                defaultMessage='Began at {time} on {date}'
                values={{
                  time: parsedDate.time,
                  date: parsedDate.date,
                }}
              />
            </EuiText>
            <EuiSpacer size='xs' />
            <EuiText color='subdued' size='xs'>
              <span>
                <FormattedMessage
                  id='cloud-platform-incident-current-status'
                  defaultMessage='Current status: {status}'
                  values={{ status: incident.status }}
                />
              </span>
            </EuiText>
          </EuiDescriptionListDescription>
        </Fragment>
      )
    })
  }

  getCloudStatusIcon(status) {
    const { indicator } = status
    let type = 'cloudSunny'

    if (indicator === 'minor') {
      type = 'cloudDrizzle'
    }

    if (indicator === 'major' || indicator === 'critical') {
      type = 'cloudStormy'
    }

    return <CuiBeveledIcon type={type} baseColor={this.getHealthIndicator(indicator)} />
  }

  getHealthIndicator = (indicator) => {
    if (indicator === 'major' || indicator === 'critical') {
      return 'danger'
    }

    if (indicator === 'none') {
      return 'success'
    }

    if (indicator === 'minor') {
      return 'warning'
    }

    return 'subdued'
  }

  toSentenceCase(str) {
    const words = str.split(' ')
    const normalisedWords = [words[0].charAt(0).toUpperCase() + words[0].slice(1)]

    for (let i = 1; i < words.length; i++) {
      normalisedWords.push(words[i].charAt(0).toLowerCase() + words[i].slice(1))
    }

    return normalisedWords.join(' ')
  }

  parseDate(strDate) {
    const dateTime = moment(strDate)
    return {
      time: moment.utc(dateTime).format('LT'),
      date: moment.utc(dateTime).format('LL'),
    }
  }
}

export default CloudStatusTile
