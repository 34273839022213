/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiDescribedFormGroup,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import usePermissions from '@modules/permissions-lib/hooks'
import { useProfile } from '@modules/profile-lib/hooks'
import genericMessages from '@modules/project-lib/genericMessages'
import type { ProjectApiError } from '@modules/ui-types/projects'
import history from '@modules/utils/history'
import { useToggle } from '@modules/utils/hooks/useToggle'
import type { AnyElasticsearchProject } from '@modules/project-api/types'

// eslint-disable-next-line import/no-restricted-paths
import DocLink from '@/components/DocLink'
// eslint-disable-next-line import/no-restricted-paths
import { supportUrl } from '@/lib/urlBuilder'
// eslint-disable-next-line import/no-restricted-paths
import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import ProjectApiErrorCallout from '../../ProjectApiErrorCallout'

import SelectSearchPowerPreset from './SelectSearchPowerPreset'
import { presets } from './presets'

import type { SearchPowerPreset } from './types'
import type { MessageDescriptor, WrappedComponentProps } from 'react-intl'

export interface Props extends WrappedComponentProps {
  project: AnyElasticsearchProject
  onChange: (searchPower: number, onSuccess: () => void) => void
  isLoading: boolean
  error: ProjectApiError | null
  allowCustomValue?: boolean
  showSearchPowerLabel?: boolean
}

function isCustomSearchPower(searchPower: number): boolean {
  return presets.every((preset) => preset.searchPower !== searchPower)
}

function getDefaultSearchPower(): number {
  return presets[1]?.searchPower ?? 100
}

const MIN_SEARCH_POWER = 28

const MAX_SEARCH_POWER = 100000

const SearchPower: React.FunctionComponent<Props> = ({
  project,
  onChange,
  isLoading,
  error,
  allowCustomValue,
  showSearchPowerLabel,
  intl,
}) => {
  const { search_lake, id } = project

  const currentSearchPower = search_lake?.search_power ?? getDefaultSearchPower()
  const isCurrentSearchPowerCustom = isCustomSearchPower(currentSearchPower)

  const isTrial = useProfile()?.is_trial ?? false

  const [isTrialLimitationsPopoverOpen, setIsTrialLimitationsPopoverOpen] = useState(true)
  const showTrialLimitationsPopover = () => setIsTrialLimitationsPopoverOpen(true)
  const hideTrialLimitationsPopover = () => setIsTrialLimitationsPopoverOpen(false)

  const isTrialLimitationsError = Boolean(
    typeof error !== 'string' &&
      error?.errors.find((e) => e.code === 'projects.patch_project.unprocessable_entity'),
  )

  useEffect(() => {
    if (error && isTrialLimitationsError) {
      showTrialLimitationsPopover()
    }
  }, [error, isTrialLimitationsError])

  useEffect(() => {
    if (isLoading) {
      hideTrialLimitationsPopover()
    }
  }, [isLoading])

  const { hasPermissions: hasUpdatePermission } = usePermissions([
    { type: `project-elasticsearch`, action: 'update', id },
  ])

  const [isEditing, toggleEditMode] = useToggle(false)
  const [searchPower, setSearchPower] = useState(currentSearchPower)

  const [isCustomSearchPowerEnabled, toggleCustomSearchPowerEnabled, setCustomSearchPowerEnabled] =
    useToggle(isCurrentSearchPowerCustom)

  const { formatMessage } = intl

  const searchPowerLabel = formatMessage(genericMessages.searchPower)

  const isInvalidCustomSearchPower =
    Number.isNaN(searchPower) || searchPower < MIN_SEARCH_POWER || searchPower > MAX_SEARCH_POWER

  function renderTrialMessage() {
    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup gutterSize='s' justifyContent='flexEnd' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiIcon color='warning' type='alert' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTextColor color='subdued'>
              <FormattedMessage
                id='project.searchPower.trialLimits'
                defaultMessage='Scaling Search Power is limited during trial. <link>Learn more</link>'
                values={{
                  link: (content) => (
                    <DocLink link='manageProject' showExternalLinkIcon={true}>
                      {content}
                    </DocLink>
                  ),
                }}
              />
            </EuiTextColor>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  return (
    <EuiPanel data-test-subj='editSearchPower' paddingSize='l' hasBorder={true}>
      <EuiFlexGroup gutterSize='xl' alignItems='stretch'>
        <EuiFlexItem grow={1}>
          <div style={{ marginRight: '2rem' }}>{renderDescription()}</div>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiFlexGroup direction='column' justifyContent='spaceBetween'>
            {renderDisplay()}
            {isEditing && renderControls()}
            <EuiFlexGroup direction='column' gutterSize='m' justifyContent='flexEnd'>
              {isEditing && renderSaveButtonAndError()}
              {isTrial && renderTrialMessage()}
            </EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )

  function renderDescription() {
    return (
      <EuiFlexGroup alignItems='baseline'>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiPanel paddingSize='m' hasBorder={true}>
            <EuiIcon type='visGauge' color='primary' size='xl' />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiTitle size='xs'>
            <h3>{searchPowerLabel}</h3>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiText size='s' color='subdued'>
            <p>
              <FormattedMessage
                id='project.searchPower.description.1'
                defaultMessage='<strong>Search Power</strong> controls how fast searches are against your project data.'
                values={{ strong: (content) => <strong>{content}</strong> }}
              />
            </p>
            {isEditing && renderEditingDescription()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  function renderEditingDescription() {
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id='project.searchPower.description.3'
            defaultMessage='With <strong>Search Power</strong>, you can either increase the performance of searches by
            adding more resources for querying data, or reduce provisioned resources to save on costs.'
            values={{ strong: (content) => <strong>{content}</strong> }}
          />
        </p>
        {!isTrial && (
          <DocLink link='manageProject' showExternalLinkIcon={true}>
            <FormattedMessage {...genericMessages.learnMore} />
          </DocLink>
        )}
      </Fragment>
    )
  }

  function renderDisplay() {
    const linkLabel = formatMessage(isEditing ? genericMessages.close : genericMessages.edit)

    return (
      <EuiFlexItem grow={false}>
        <EuiSpacer size='xs' />
        <EuiFlexGroup
          responsive={false}
          gutterSize='m'
          justifyContent='flexEnd'
          alignItems='baseline'
          style={{ whiteSpace: 'nowrap' }}
        >
          <EuiFlexItem grow={false}>
            <EuiTextColor color='subdued'>
              <FormattedMessage {...genericMessages.current} />:
            </EuiTextColor>
          </EuiFlexItem>
          <EuiFlexItem grow={false} data-test-subj='currentValue'>
            <strong>{displayLabel()}</strong>
          </EuiFlexItem>
          {hasUpdatePermission && (
            <EuiFlexItem grow={false}>
              <EuiLink
                onClick={() => {
                  if (!isCustomSearchPower(searchPower)) {
                    setCustomSearchPowerEnabled(false)
                  }

                  toggleEditMode()
                }}
                aria-label={`${linkLabel} search power. Current value is ${currentSearchPower}.`}
                aria-live='polite'
              >
                <FormattedMessage {...(isEditing ? genericMessages.close : genericMessages.edit)} />
              </EuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function displayLabel() {
    const currentPreset = presets.find(
      (preset: SearchPowerPreset) => preset.searchPower === currentSearchPower,
    )

    const messageDescriptor = currentPreset ? currentPreset.label : genericMessages.custom

    return formatMessageWithSuffix(messageDescriptor, currentSearchPower)
  }

  function formatMessageWithSuffix(messageDescriptor: MessageDescriptor, searchPower: number) {
    const suffix = showSearchPowerLabel ? ` (${searchPower})` : ''

    return formatMessage(messageDescriptor) + suffix
  }

  function renderControls() {
    return (
      <EuiFlexItem grow={true}>
        <EuiSpacer size='xl' />
        {!allowCustomValue && isCurrentSearchPowerCustom
          ? renderContactSupportMessage()
          : renderSearchPowerControls()}
      </EuiFlexItem>
    )
  }

  function renderSearchPowerControls() {
    return (
      <Fragment>
        <SelectSearchPowerPreset
          value={searchPower}
          disabled={isCustomSearchPowerEnabled}
          presets={presets.map(({ label, searchPower }) => ({
            label: formatMessageWithSuffix(label, searchPower),
            searchPower,
          }))}
          onChange={setSearchPower}
        />
        {allowCustomValue && (
          <Fragment>
            <EuiHorizontalRule />
            <EuiSpacer size='m' />
            {renderCustomSearchPowerInput()}
          </Fragment>
        )}
      </Fragment>
    )
  }

  function renderContactSupportMessage() {
    return (
      <EuiText size='s' color='subdued'>
        <FormattedMessage
          id='project.searchPower.custom_not_allowed'
          defaultMessage='Your Search Power is set to the custom value of {value}. You can {resetButton} it back to a preset 
            value, or  request a change to this value via <link>Support</link>.'
          values={{
            value: <strong>{currentSearchPower}</strong>,
            resetButton: (
              <EuiLink
                onClick={() => {
                  const defaultSearchPower = getDefaultSearchPower()

                  setSearchPower(defaultSearchPower)

                  onChange(getDefaultSearchPower(), toggleEditMode)
                }}
                aria-label={formatMessage(genericMessages.accessibleReset)}
              >
                <FormattedMessage {...genericMessages.reset} />
              </EuiLink>
            ),
            link: (content) => (
              <EuiLink onClick={() => history.push(supportUrl())}>{content}</EuiLink>
            ),
          }}
        />
      </EuiText>
    )
  }

  function renderCustomSearchPowerInput() {
    return (
      <Fragment>
        <EuiDescribedFormGroup
          title={
            <FormattedMessage
              id='project.searchPower.custom.label'
              defaultMessage='Custom search power'
            />
          }
          description='Enter a custom number for the Search Power'
        >
          <EuiFlexGroup gutterSize='s' direction='column'>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize='s' justifyContent='flexEnd'>
                <EuiSwitch
                  showLabel={false}
                  label={intl.formatMessage({
                    id: 'project.searchPower.custom.switch.label',
                    defaultMessage: 'Enable custom search power',
                  })}
                  checked={isCustomSearchPowerEnabled}
                  onChange={() => {
                    const newSearchPower = isCustomSearchPower(searchPower)
                      ? getDefaultSearchPower()
                      : searchPower

                    setSearchPower(newSearchPower)
                    toggleCustomSearchPowerEnabled()
                  }}
                />
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiDescribedFormGroup>
        {isCustomSearchPowerEnabled && (
          <EuiFormRow
            fullWidth={true}
            isInvalid={isInvalidCustomSearchPower}
            error={[`Value must be between ${MIN_SEARCH_POWER} and ${MAX_SEARCH_POWER}`]}
          >
            <EuiFieldNumber
              fullWidth={true}
              min={MIN_SEARCH_POWER}
              max={MAX_SEARCH_POWER}
              value={Number.isNaN(searchPower) ? '' : searchPower}
              onChange={(e) => {
                const newSearchPower = Number.parseInt(e.target.value, 10)

                setSearchPower(newSearchPower)
              }}
              readOnly={!isCustomSearchPowerEnabled}
              aria-label='Custom search power value'
              isInvalid={isInvalidCustomSearchPower}
              required={true}
            />
          </EuiFormRow>
        )}
      </Fragment>
    )
  }

  function renderSaveButtonAndError() {
    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction='column' gutterSize='s' alignItems='flexEnd'>
          {!isTrialLimitationsError && <ProjectApiErrorCallout error={error} />}
          <EuiFlexItem grow={false}>{renderSaveButton()}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function renderSaveButton() {
    const saveButton = (
      <EuiButton
        size='s'
        color='primary'
        fill={true}
        isDisabled={currentSearchPower === searchPower || isInvalidCustomSearchPower}
        isLoading={isLoading}
        minWidth='6rem'
        onClick={() => {
          onChange(searchPower, toggleEditMode)
        }}
      >
        <FormattedMessage {...genericMessages.save} />
      </EuiButton>
    )

    if (isTrialLimitationsError) {
      return (
        <EuiPopover
          button={saveButton}
          isOpen={isTrialLimitationsPopoverOpen}
          closePopover={hideTrialLimitationsPopover}
          anchorPosition='upCenter'
        >
          <EuiText css={css({ maxWidth: '290px' })}>
            <FormattedMessage
              id='project.search-power-trial-limitation'
              defaultMessage='Scaling Search Power is limited during trial. {subscribe} now to unlock more scaling options.'
              values={{
                subscribe: (
                  <CreditCardModalButton onCloseModal={hideTrialLimitationsPopover}>
                    <FormattedMessage id='portal.subscribe' defaultMessage='Subscribe' />
                  </CreditCardModalButton>
                ),
              }}
            />
          </EuiText>
        </EuiPopover>
      )
    }

    return saveButton
  }
}

export default injectIntl(SearchPower)
