/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { css } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiImage,
  EuiLink,
  EuiListGroup,
  EuiListGroupItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui'

import ProjectList from '@modules/project-user-components/ProjectList'
import { useProjectsMetadata } from '@modules/project-user-components/SupplyProjectsMetadata'
import aerospaceIllustration from '@modules/project-user-lib/images/illustration-aerospace-128.svg'

import type { FC } from 'react'
import type { MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  panelIllustration: {
    id: 'project.panel.illustration',
    defaultMessage: 'Aerospace illustration',
  },
  noExpertise: {
    id: 'project.portalPanel.no-expertise-needed',
    defaultMessage: 'Scales automatically to workloads',
  },
  payUsage: {
    id: 'project.portalPanel.single-solution',
    defaultMessage: 'Simple, usage-based pricing',
  },
  noCluster: {
    id: 'project.portalPanel.pay-only-for-usage',
    defaultMessage: 'No cluster and node management required',
  },
  navigation: {
    id: 'project.portalPanel.pay-only-for-usage-each',
    defaultMessage: 'Streamlined onboarding optimized for your use case',
  },
  learnMoreAriaLabel: {
    id: 'project.portalPanel.learn-more.aria-label',
    defaultMessage: 'Learn more about serverless projects',
  },
})

const FeatureBullet: React.FunctionComponent<{ feature: MessageDescriptor }> = ({ feature }) => {
  const { euiTheme } = useEuiTheme()

  return (
    <EuiListGroupItem
      key={feature.id}
      label={
        <EuiText size='s'>
          <FormattedMessage {...feature} />
        </EuiText>
      }
      iconType='check'
      iconProps={{ color: euiTheme.colors.success }}
      wrapText={true}
    />
  )
}

const ProjectsPortalPanelContent: FC = () => {
  const { euiTheme } = useEuiTheme()
  const { formatMessage } = useIntl()

  const featureBulletListStyles = css`
    display: flex;
    flex-wrap: wrap;
    li {
      margin-top: ${euiTheme.size.s};
      flex: 50%;
    }
  `

  const { projectsLength, isReady } = useProjectsMetadata()

  if (!isReady) {
    return <EuiSkeletonText />
  }

  if (projectsLength === 0) {
    return (
      <Fragment>
        <EuiHorizontalRule />

        <EuiFlexGroup alignItems='center' gutterSize='xl' justifyContent='spaceBetween'>
          <EuiFlexItem grow={1}>
            <EuiImage
              size='s'
              src={aerospaceIllustration}
              alt={formatMessage(messages.panelIllustration)}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={4}>
            <EuiTitle size='s'>
              <h3>
                <FormattedMessage
                  id='project.portalPanel.zeroProjects.title'
                  defaultMessage='Create your first serverless project'
                />
              </h3>
            </EuiTitle>

            <EuiSpacer size='m' />

            <EuiText size='s'>
              <p>
                <FormattedMessage
                  id='project.portalPanel.zeroProjects.body'
                  defaultMessage='Create a project for an autoscaled and fully managed Elastic solution based on Elasticsearch, Observability, or Security. <link>Learn more</link>'
                  values={{
                    link: (content) => (
                      <EuiLink
                        href='https://ela.st/fully-managed-projects'
                        aria-label={formatMessage(messages.learnMoreAriaLabel)}
                        target='_blank'
                      >
                        {content}
                      </EuiLink>
                    ),
                  }}
                />
              </p>
            </EuiText>
            <EuiSpacer size='s' />
            <EuiListGroup css={featureBulletListStyles}>
              <FeatureBullet key={messages.noExpertise.id} feature={messages.noExpertise} />
              <FeatureBullet key={messages.payUsage.id} feature={messages.payUsage} />
              <FeatureBullet key={messages.noCluster.id} feature={messages.noCluster} />
              <FeatureBullet key={messages.navigation.id} feature={messages.navigation} />
            </EuiListGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiSpacer size='m' />
      <ProjectList limit={5} />
    </Fragment>
  )
}

export default ProjectsPortalPanelContent
