/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { parse } from 'query-string'
import jwtDecode from 'jwt-decode'

import { EuiSkeletonText } from '@elastic/eui'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import LandingPageContainer from '@/components/LandingPageContainer'

import PartnerSignup from '../GCPMarketplaceSignupV0'

import type { RouteComponentProps } from 'react-router'

type Props = RouteComponentProps

type JwtBearerToken = {
  sub: string
  aud: string
  iat: Date
  exp: Date
  google: {
    user_identity: string
    roles: string[]
    consumer_project_number?: string
  }
  iss: string
}

const MarketplaceRegistrationRedirect: FunctionComponent<Props> = ({ location }) => {
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  if (!isFlagUsable) {
    return (
      <LandingPageContainer
        isLoading={true}
        panelProps={{
          hasShadow: false,
          hasBorder: false,
        }}
      >
        <EuiSkeletonText />
      </LandingPageContainer>
    )
  }

  const query = parse(location.search.slice(1))
  const token = String(query.token)
  let jwt

  try {
    jwt = jwtDecode<JwtBearerToken>(token)
  } catch {
    return <Route component={PartnerSignup} />
  }

  if (jwt?.google?.consumer_project_number) {
    return <Route component={PartnerSignup} />
  }

  if (flags && flags.gcpMpFlag) {
    return (
      <Redirect
        to={{
          pathname: '/marketplace-registration',
          search: `?source=partners&partner=gcp&redirectTo=%2Fmarketplace-conversion%23token%3D${token}%26partner%3Dgcp`,
        }}
      />
    )
  }

  return <Route component={PartnerSignup} />
}

export default MarketplaceRegistrationRedirect
