/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { compact } from 'lodash'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
  EuiAvatar,
} from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import {
  flattenRoleAssignments,
  getEmptyRoleAssignments,
  isRoleAssignmentsEmpty,
} from '@modules/role-assignments-lib'

import RoleAssignmentsPanel from '@/components/Users/RoleAssignmentsPanel'
import { diffRoleAssignments } from '@/components/Users/RoleAssignmentsPanel/lib'
import SpinButton from '@/components/SpinButton'

import OrganizationMemberStatus from '../OrganizationMemberStatus'

import RoleAssignmentConfirmationModal from './RoleAssignmentConfirmationModal'
import RolesSummary from './RolesSummary'
import { getOverridingRoleInfo, useGetDeploymentsAndProjectsQuery } from './lib'

import type { OverriddenRoleAndNewRole, OverridingRole } from '../lib/types'
import type { OrganizationMemberRow } from '../types'
import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

const OrganizationMemberRoleAssignments: FunctionComponent<Props> = ({
  resetUpdateRoleAssignmentsRequest,
  member,
  organizationId,
  isCurrentUser,
  isSaving,
  onClose,
  updateRoleAssignments,
  fetchOrganizationMembers,
}) => {
  const [isEditing, setEditing] = useState(false)
  const [roleAssignments, setRoleAssignments] = useState(
    member.roleAssignments ?? getEmptyRoleAssignments(),
  )
  const [overridingAllRoles, setOverridingAllRoles] = useState<OverridingRole[]>([])
  const [overriddenRoles, setOverriddenRoles] = useState<OverriddenRoleAndNewRole[]>([])
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const userResourceList = compact(
    useGetDeploymentsAndProjectsQuery(organizationId)
      .filter(({ data }) => !!data)
      .flatMap((resourceGroup) => {
        const { data, resourceType } = resourceGroup
        return data?.map(({ id, name }) => ({
          resourceType,
          id,
          name,
        }))
      }),
  )
  const getOriginalRoleAssignments = (): RoleAssignments =>
    member.roleAssignments ?? getEmptyRoleAssignments()
  const { added: addedRoles, removed: removedRoles } = diffRoleAssignments(
    getOriginalRoleAssignments(),
    roleAssignments,
  )

  useEffect(() => {
    resetUpdateRoleAssignmentsRequest()
  })

  const onSave = () => {
    const overridingRolesInfo = getOverridingRoleInfo({
      addedRoleAssignments: flattenRoleAssignments(addedRoles),
      removedRoleAssignments: flattenRoleAssignments(removedRoles),
      userResourceList,
      roleAssignments: flattenRoleAssignments(roleAssignments),
    })

    if (!overridingRolesInfo) {
      return onConfirmRoleChanges()
    }

    setOverridingAllRoles(overridingRolesInfo.overridingAllRoles)
    setOverriddenRoles(overridingRolesInfo.overriddenRoles)
    setConfirmationModalOpen(true)
  }

  const onConfirmRoleChanges = () => {
    setConfirmationModalOpen(false)
    const diff = { added: addedRoles, removed: removedRoles }

    updateRoleAssignments(roleAssignments, diff)
      .then(() => {
        onClose()
        fetchOrganizationMembers({ organizationId })
      })
      .then(() =>
        addToast({
          family: 'organization.organization-member-role-assignments',
          color: 'success',
          iconType: 'check',
          title: (
            <FormattedMessage
              id='organization.organization-member-role-assignments.success'
              defaultMessage='Role changes successfully applied.'
            />
          ),
        }),
      )
      .catch((error) =>
        addToast({
          family: 'organization.organization-member-role-assignments',
          color: 'danger',
          title: (
            <FormattedMessage
              id='organization.organization-member-role-assignments.failure'
              defaultMessage="We couldn't update the roles of this member. Try again later."
            />
          ),
          text: parseError(error),
        }),
      )
  }

  const isSaveButtonEnabled =
    !isRoleAssignmentsEmpty(addedRoles) || !isRoleAssignmentsEmpty(removedRoles)

  return (
    <Fragment>
      {isConfirmationModalOpen && (
        <RoleAssignmentConfirmationModal
          overridingAllRoles={overridingAllRoles}
          overriddenRoles={overriddenRoles}
          onCloseModal={setConfirmationModalOpen.bind(null, false)}
          onConfirmRoleAssignmentChange={onConfirmRoleChanges}
        />
      )}
      <EuiFlyout maxWidth='57rem' onClose={onClose} paddingSize='l'>
        <EuiFlyoutHeader hasBorder={true}>
          <FlyoutHeader member={member} />
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <FlyoutBody
            organizationId={organizationId}
            member={member}
            isEditing={isEditing}
            isCurrentUser={isCurrentUser}
            setEditing={setEditing}
            roleAssignments={roleAssignments}
            setRoleAssignments={setRoleAssignments}
            getOriginalRoleAssignments={getOriginalRoleAssignments}
          />
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <FlyoutFooter
            isEditing={isEditing}
            isSaveButtonEnabled={isSaveButtonEnabled}
            isSaving={isSaving}
            onClose={onClose}
            onSave={onSave}
          />
        </EuiFlyoutFooter>
      </EuiFlyout>
    </Fragment>
  )
}

const FlyoutFooter = ({
  isEditing,
  isSaveButtonEnabled,
  isSaving,
  onClose,
  onSave,
}: {
  isEditing: boolean
  isSaveButtonEnabled: boolean
  isSaving: boolean
  onClose: () => void
  onSave: () => void
}) => (
  <EuiFlexGroup justifyContent='spaceBetween'>
    <EuiFlexItem grow={false}>
      <EuiButtonEmpty flush='left' onClick={onClose}>
        <FormattedMessage
          id='organization.organization-member-role-assignments.cancel'
          defaultMessage='Cancel'
        />
      </EuiButtonEmpty>
    </EuiFlexItem>

    {isEditing && (
      <EuiFlexItem grow={false}>
        <SpinButton onClick={onSave} fill={true} isDisabled={!isSaveButtonEnabled} spin={isSaving}>
          <FormattedMessage
            id='organization.organization-member-role-assignments.save'
            defaultMessage='Save'
          />
        </SpinButton>
      </EuiFlexItem>
    )}
  </EuiFlexGroup>
)

const FlyoutHeader = ({ member }: { member: OrganizationMemberRow }) => (
  <EuiFlexGroup gutterSize='m' responsive={false}>
    <EuiFlexItem>
      <EuiTitle>
        <h2>{member.name ?? member.email}</h2>
      </EuiTitle>
    </EuiFlexItem>

    <EuiFlexItem
      grow={false}
      css={css({
        marginTop: '0.5em',
      })}
    >
      <EuiFlexGroup direction='column' alignItems='flexEnd' justifyContent='center' gutterSize='m'>
        <EuiFlexItem grow={false}>
          <strong>
            <FormattedMessage
              id='organization.organization-member-role-assignments.user-id'
              defaultMessage='User ID'
            />
          </strong>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>{member.id}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  </EuiFlexGroup>
)

const FlyoutBody = ({
  organizationId,
  member,
  isEditing,
  isCurrentUser,
  setEditing,
  roleAssignments,
  setRoleAssignments,
  getOriginalRoleAssignments,
}: {
  organizationId: string
  member: OrganizationMemberRow
  isEditing: boolean
  isCurrentUser: boolean
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  roleAssignments: RoleAssignments
  setRoleAssignments: React.Dispatch<React.SetStateAction<RoleAssignments>>
  getOriginalRoleAssignments: () => RoleAssignments
}) => (
  <EuiFlexGroup direction='column' gutterSize='xl'>
    <EuiFlexItem grow={false}>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiAvatar size='m' name={(member.name ?? member.email).toUpperCase()} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction='column' gutterSize='s'>
            <EuiFlexItem>
              <strong>{member.name ?? member.email}</strong>
            </EuiFlexItem>

            {member.name !== undefined && <EuiFlexItem>{member.email}</EuiFlexItem>}

            <EuiFlexItem>
              <OrganizationMemberStatus
                organizationId={organizationId}
                organizationMemberRow={member}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <EuiFlexGroup alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='organization.organization-member-role-assignments.roles'
                defaultMessage='Roles'
              />
            </h3>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            size='s'
            onClick={() => {
              setEditing((prev) => !prev)
              setRoleAssignments(getOriginalRoleAssignments())
            }}
            flush='left'
            disabled={isCurrentUser}
          >
            {isEditing ? (
              <FormattedMessage
                id='organization.organization-member-role-assignments.cancel-edit'
                defaultMessage='Cancel edit'
              />
            ) : (
              <FormattedMessage
                id='organization.organization-member-role-assignments.edit'
                defaultMessage='Edit'
              />
            )}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    <EuiFlexItem>
      {isEditing ? (
        <RoleAssignmentsPanel
          memberOrApiKey='member'
          organizationId={organizationId}
          roleAssignments={roleAssignments}
          onChangeRoleAssignments={setRoleAssignments}
        />
      ) : (
        <RolesSummary
          memberOrApiKey='member'
          organizationId={organizationId}
          roleAssignments={roleAssignments}
        />
      )}
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default OrganizationMemberRoleAssignments
