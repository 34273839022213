/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import {
  getElasticsearchProject,
  getElasticsearchProjectRoles,
  getObservabilityProject,
  getObservabilityProjectRoles,
  getSecurityProject,
  getSecurityProjectRoles,
} from '@modules/project-user-api/v1/callers'
import type { ProjectID } from '@modules/project-user-api/v1/types'
import type { QueryHook, QueryHookOptions } from '@modules/query/types'
import type { ProjectApiError, ProjectType } from '@modules/ui-types/projects'

import { makeQueryKey } from './helpers'

/**
 * A convenience wrapper hook to get a single project.
 */
export function useGetProjectQuery(
  type: ProjectType,
  id: ProjectID,
  options: QueryHookOptions = {},
) {
  const elasticsearchQuery = useGetElasticsearchProjectQuery(id, makeOnwardOptions('elasticsearch'))
  const observabilityQuery = useGetObservabilityProjectQuery(id, makeOnwardOptions('observability'))
  const securityQuery = useGetSecurityProjectQuery(id, makeOnwardOptions('security'))

  return {
    elasticsearch: elasticsearchQuery,
    observability: observabilityQuery,
    security: securityQuery,
  }[type]

  function makeOnwardOptions(projectType: ProjectType) {
    const consumerEnabled = options.enabled ?? true // true being the useQuery default

    return {
      ...options,
      enabled: type === projectType && consumerEnabled,
    }
  }
}

const useGetElasticsearchProjectQuery: QueryHook<
  typeof getElasticsearchProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getElasticsearchProject', id), () =>
    getElasticsearchProject({
      pathParameters: { id },
    }),
  )

const useGetObservabilityProjectQuery: QueryHook<
  typeof getObservabilityProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getObservabilityProject', id), () =>
    getObservabilityProject({
      pathParameters: { id },
    }),
  )

const useGetSecurityProjectQuery: QueryHook<
  typeof getSecurityProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getSecurityProject', id), () =>
    getSecurityProject({
      pathParameters: { id },
    }),
  )

export const useGetElasticsearchProjectRolesQuery: QueryHook<
  typeof getElasticsearchProjectRoles
> = (id: ProjectID, options?: { enabled?: boolean }) =>
  useQuery(
    makeQueryKey('getElasticsearchProjectRoles', id),
    () =>
      getElasticsearchProjectRoles({
        pathParameters: { id },
      }),
    options,
  )

// ToDo: remove disable below when custom roles are enabled Observability
// and this export gets used
// ts-unused-exports:disable-next-line
export const useGetObservabilityProjectRolesQuery: QueryHook<
  typeof getObservabilityProjectRoles
> = (id: ProjectID, options?: { enabled?: boolean }) =>
  useQuery(
    makeQueryKey('getObservabilityProjectRoles', id),
    () =>
      getObservabilityProjectRoles({
        pathParameters: { id },
      }),
    { enabled: options?.enabled ?? true },
  )

export const useGetSecurityProjectRolesQuery: QueryHook<typeof getSecurityProjectRoles> = (
  id: ProjectID,
  options?: { enabled?: boolean },
) =>
  useQuery(
    makeQueryKey('getSecurityProjectRoles', id),
    () =>
      getSecurityProjectRoles({
        pathParameters: { id },
      }),
    { enabled: options?.enabled ?? true },
  )
