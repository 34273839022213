/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
  EuiDescriptionList,
} from '@elastic/eui'
import type { EuiDescriptionListProps } from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { getEmptyRoleAssignments } from '@modules/role-assignments-lib'

import RolesSummary from '../Organization/OrganizationMembers/OrganizationMemberRoleAssignments/RolesSummary'

import { ApiKeyStatus } from './ApiKeyStatus'

import type { ApiKeyRow } from './types'

type State = {
  roleAssignments: RoleAssignments
}

type Props = {
  apiKey: ApiKeyRow
  organizationId: string
  onClose: () => void
}

class ApiKeysRoleAssignments extends Component<Props, State> {
  state: State = this.createInitialState()

  render() {
    return (
      <EuiFlyout
        maxWidth='57rem'
        onClose={this.props.onClose}
        paddingSize='l'
        className={'apiKeys-summary-view'}
      >
        <EuiFlyoutHeader hasBorder={true}>{this.renderFlyoutHeader()}</EuiFlyoutHeader>
        <EuiFlyoutBody>{this.renderFlyoutBody()}</EuiFlyoutBody>
        <EuiFlyoutFooter>{this.renderFlyoutFooter()}</EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderFlyoutHeader() {
    const { apiKey } = this.props

    return (
      <EuiTitle>
        <h2>
          {apiKey.description} <ApiKeyStatus apiKey={apiKey} />
        </h2>
      </EuiTitle>
    )
  }

  renderFlyoutBody() {
    const { organizationId, apiKey } = this.props

    const { roleAssignments } = this.state

    const listItems: EuiDescriptionListProps['listItems'] = [
      {
        title: <FormattedMessage id='apikeys.flyout.createdBy' defaultMessage='Created by' />,
        description: apiKey.createdBy,
      },
      {
        title: <FormattedMessage id='apikeys.flyout.creationDate' defaultMessage='Created' />,
        description: moment(apiKey.creation_date).format('MMMM Do YYYY, h:mm a'),
      },
      {
        title: <FormattedMessage id='apikeys.flyout.expirationDate' defaultMessage='Expires' />,
        description: moment(apiKey.creation_date).format('MMMM Do YYYY, h:mm a'),
      },
    ]

    return (
      <EuiFlexGroup direction='column' gutterSize='xl'>
        <EuiFlexItem>
          <EuiDescriptionList
            compressed={true}
            type='responsiveColumn'
            rowGutterSize='s'
            listItems={listItems}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiTitle size='s'>
            <h3>
              <FormattedMessage id='apikeys.apikey-role-assignments.roles' defaultMessage='Roles' />
            </h3>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem>
          <RolesSummary
            memberOrApiKey='api-key'
            organizationId={organizationId}
            roleAssignments={roleAssignments}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderFlyoutFooter() {
    return (
      <EuiButtonEmpty flush='left' onClick={this.props.onClose}>
        <FormattedMessage
          id='organization.organization-member-role-assignments.cancel'
          defaultMessage='Cancel'
        />
      </EuiButtonEmpty>
    )
  }

  createInitialState(): State {
    return {
      roleAssignments: this.props.apiKey.role_assignments ?? getEmptyRoleAssignments(),
    }
  }

  onChangeRoleAssignments = (roleAssignments: RoleAssignments) => {
    this.setState({ roleAssignments })
  }

  getOriginalRoleAssignments = (): RoleAssignments =>
    this.props.apiKey.role_assignments ?? getEmptyRoleAssignments()
}

export default ApiKeysRoleAssignments
