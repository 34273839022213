/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexItem, EuiFlexGroup } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import { withSmallErrorBoundary } from '@modules/cui/Boundary'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { getKibanaRedirectPageUrl } from '@modules/kibana-redirect/url'
import { AutoopsTour } from '@modules/auto-ops-components/Tour/AutoopsTour'
import { useConfig } from '@modules/cui/ConfigContext'

import { getConfigForKey } from '@/store/store'
import { isDeploymentAvailableInKibana } from '@/lib/stackDeployments/selectors/available'
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'

import StackDeploymentAdminActionsDropdown from './StackDeploymentAdminActionsDropdown'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  hideClusterInsteadOfDelete: boolean
  hideClusterInsteadOfStop: boolean
}

const StackDeploymentAdminActions: FunctionComponent<Props> = ({
  deployment,
  hideClusterInsteadOfDelete,
  hideClusterInsteadOfStop,
}) => (
  <EuiFlexGroup
    gutterSize='m'
    responsive={false}
    className='deploymentOverview-managementControls fs-unmask'
  >
    <EuiFlexItem grow={false}>
      <AutoOpsButton deployment={deployment} />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <LaunchKibanaButton deployment={deployment} />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <StackDeploymentAdminActionsDropdown
        deployment={deployment}
        hideClusterInsteadOfDelete={hideClusterInsteadOfDelete}
        hideClusterInsteadOfStop={hideClusterInsteadOfStop}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
)

const LaunchKibanaButton = ({ deployment }: { deployment: StackDeployment }) => {
  const {
    resources: { kibana },
  } = deployment

  const isDeploymentAvailable = isDeploymentAvailableInKibana(deployment)

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  if (!kibana || kibana.length === 0) {
    return null
  }

  const href = isAdminConsole
    ? getDeploymentResourceEndpoint({ deployment, sliderInstanceType: `kibana` })
    : getKibanaRedirectPageUrl(`deployment`, deployment.id)

  return (
    <EuiButton
      href={href}
      isDisabled={!isDeploymentAvailable}
      data-test-id='deployment-launch-kibana'
    >
      <FormattedMessage id='deployment-admin-actions.kibana' defaultMessage='Open Kibana' />
    </EuiButton>
  )
}

const AutoOpsButton = ({ deployment }: { deployment: StackDeployment }) => {
  const [_isFlagUsable, flags] = useFlagsWhenLoaded()

  const {
    metadata,
    resources: { elasticsearch },
    settings,
  } = deployment

  const { showAutoOps, autoOpsTourButton } = flags

  if (!elasticsearch[0] || metadata == null || metadata.organization_id == null) {
    return null
  }

  const isAdminconsole = getConfigForKey(`APP_NAME`) === `adminconsole`
  const isSaas = getConfigForKey(`APP_PLATFORM`) === `saas`

  // the logic currently is that we only show the button if we are in the adminconsole and saas OR if the LD flag is enabled
  if ((!isAdminconsole || !isSaas) && !showAutoOps) {
    return null
  }

  const isAutoOpsStatusConnected = settings?.auto_ops.status === `connected`

  // there is a field in the deployment response that we check to make sure we show this button
  if (!isAutoOpsStatusConnected) {
    return null
  }

  const href = `https://app.auto-ops.${window.location.hostname}/regions/${elasticsearch[0].region}/organizations/${metadata.organization_id}/deployments/${deployment.id}/deployment`

  const OpenAutoOpsButton = () => (
    <EuiButton href={href} data-test-id='deployment-launch-auto-ops'>
      <FormattedMessage id='deployment-admin-actions.auto-ops' defaultMessage='Open AutoOps' />
    </EuiButton>
  )

  // Show the tour if we are in the adminconsole and saas OR if the LD flag - autoOpsTourButton is enabled
  return (isAdminconsole && isSaas) || autoOpsTourButton ? (
    <AutoopsTour>
      <OpenAutoOpsButton />
    </AutoopsTour>
  ) : (
    <OpenAutoOpsButton />
  )
}

export default withSmallErrorBoundary(StackDeploymentAdminActions)
