/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHealth, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import type { CuiTableColumn } from '@modules/cui/Table'
import { CuiTable } from '@modules/cui/Table'
import { CuiHelpTipIcon } from '@modules/cui/HelpTipIcon'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { DeploymentInstance, ProjectInstance } from '@modules/billing-api/customTypes'
import { colorForProduct } from '@modules/billing-lib/colorGenerator'
import type { components } from '@modules/billing-api/v1/types'

import EmptyDataPrompt from '@/components/User/BillingUsage/BillingUsageOverviewV2/EmptyDataPrompt'
import DocLink from '@/components/DocLink'

import { IconType } from './types'
import { getCurrentProductList, getProductName, getProductTypeName } from './utils'
import ProductDetailsPanel from './ProductDetailsPanel'

import type { ReducerState } from '../components/Filters/filtersReducer'
import type { FunctionComponent } from 'react'

interface Props {
  products: components['schemas']['Items']['products']
  isLoading: boolean
  filters: ReducerState
}

const ProductsList: FunctionComponent<Props> = ({ products, isLoading, filters }) => {
  const items = getCurrentProductList({ products, filters })
  const totalCost = items.reduce((total, { total_ecu }) => total + total_ecu, 0)

  const columns: Array<CuiTableColumn<ProjectInstance | DeploymentInstance>> = [
    {
      label: (
        <FormattedMessage id='billing-usage-products-list.product-label' defaultMessage='Product' />
      ),
      render: ({ type }) => (
        <EuiHealth color={colorForProduct.get(type)}>
          <span data-test-id='name'>{getProductName(type)}</span>
        </EuiHealth>
      ),
      width: '70%',
      footer: {
        render: () => (
          <Fragment>
            <FormattedMessage id='billing-usage-products-list.total' defaultMessage='Total usage' />
            <CuiHelpTipIcon iconType='iInCircle'>
              <FormattedMessage
                id='billing-deployment-usage.tip-icon'
                defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
              />
            </CuiHelpTipIcon>
          </Fragment>
        ),
      },
    },
    {
      label: <FormattedMessage id='billing-usage-products-list.type-label' defaultMessage='Type' />,
      render: ({ type }) => getProductTypeName(type),
      truncateText: true,
      footer: {
        render: () => null,
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-products-list.total-label'
          defaultMessage='Total (in ECU)'
        />
      ),
      render: ({ total_ecu }) => (
        <CuiElasticConsumptionUnits value={total_ecu} unit='none' dp={4} withSymbol={false} />
      ),
      footer: {
        render: () => (
          <CuiElasticConsumptionUnits
            value={totalCost}
            unit='none'
            dp={4}
            withSymbol={true}
            symbolPosition='before'
          />
        ),
      },
    },
    {
      actions: true, // additional actions column
      width: '40px',
    },
  ]

  return (
    <Fragment>
      <EuiText size='s'>
        <h3>
          <FormattedMessage id='billing-usage-products-list.title' defaultMessage='Products' />
        </h3>
      </EuiText>

      <EuiSpacer size='xs' />

      <EuiText size='s'>
        <FormattedMessage
          id='billing-deployment-usage-list.description'
          defaultMessage='All cost units are measured in {ecu}.'
          values={{
            ecu: (
              <DocLink link='billingECU' favorSaasContext={true}>
                <FormattedMessage
                  id='ecu-tooltip.page-ecu-unit'
                  defaultMessage='Elastic Consumption Units (ECU)'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>

      <EuiSpacer size='m' />

      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        className='billing-usage-responsive-panel'
        data-test-id='products-list'
      >
        <CuiTable
          rows={items}
          columns={columns}
          initialLoading={isLoading}
          hasFooterRow={products.length > 0}
          responsive={false}
          getRowId={(item) => item.type}
          emptyMessage={<EmptyDataPrompt iconType={IconType.TABLE} />}
          renderDetailRow={(item) => (
            <ProductDetailsPanel productLineItems={item.product_line_items} />
          )}
          hasDetailRow={true}
          renderDetailButton={true}
        />
      </EuiPanel>
    </Fragment>
  )
}

export default ProductsList
