/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { filter, find, some } from 'lodash'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiDescribedFormGroup,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
  EuiModal,
  EuiModalBody,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiButton,
  EuiBetaBadge,
} from '@elastic/eui'

import type { SaasAuthResponse } from '@modules/cloud-api/v1/types'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import type { AsyncRequestState } from '@modules/ui-types'
import type { DeviceType } from '@modules/mfa-management/types'
import DeviceOption from '@modules/mfa-management/DeviceOption'

import MfaForm from '../Login/PasswordBasedLogin/MfaForm'
import DocLink from '../DocLink'

import MfaProvider from './MfaProvider'
import GoogleAuthenticator from './GoogleAuthenticator'
import TextMessageAuthentication from './TextMessageAuthentication'

import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { WrappedComponentProps } from 'react-intl'
import type { RenderProps } from './MfaProvider/types'
import type { MfaDevice } from '../../apps/userconsole/reducers/mfa/types'
import type { MfaState, AsyncAction } from '@/types/redux'
import type { MfaDeviceState } from '../../reducers/auth/types'
import type { ReactNode } from 'react'

import './deviceAuthentication.scss'

type State = { isMfaFormOpen: boolean }

export interface Props {
  mfa: MfaState
  mfaEnabled: boolean | null
  mfaDevices: MfaDevice[] | null
  enableMfa: () => void
  disableMfa: () => void
  disableMfaDeviceRequest: AsyncRequestState
  enableMfaDeviceRequest: AsyncRequestState
  challengeSaasCurrentUserMfaFactor: (args: {
    device_id: string
  }) => Promise<AsyncAction<'CHALLENGE_CURRENT_USER_MFA_FACTOR', unknown>>
  verifySaasCurrentUserMfaFactorRequest: AsyncRequestState
  verifySaasCurrentUserMfaFactor: (args: {
    device_id: string
    pass_code: string
  }) => Promise<AsyncAction<'VERIFY_CURRENT_USER_MFA_FACTOR', SaasAuthResponse>>
  resetVerifySaasCurrentUserMfaFactorRequest: () => void
}

const enabledLabel = (
  <FormattedMessage id='uc.accountSecurity.enabledValue' defaultMessage='Enabled' />
)
const disabledLabel = (
  <FormattedMessage id='uc.accountSecurity.disabledValue' defaultMessage='Disabled' />
)
const enableForbidden = (
  <FormattedMessage
    id='uc.accountSecurity.enableForbidden'
    defaultMessage='Disabled. Add a device first.'
  />
)

class DeviceAuthentication extends PureComponent<Props & WrappedComponentProps & LDProps> {
  state: State = { isMfaFormOpen: false }

  render() {
    const { isMfaFormOpen } = this.state

    return (
      <div>
        {this.renderForm()}
        {isMfaFormOpen && this.renderMfaForm()}
      </div>
    )
  }

  renderForm() {
    const {
      disableMfaDeviceRequest,
      enableMfaDeviceRequest,
      intl: { formatMessage },
    } = this.props
    const hasEnableDisableRequestError =
      disableMfaDeviceRequest.error || enableMfaDeviceRequest.error

    const learnMoreAriaLabel = formatMessage({
      id: 'device-authentication.link.aria-label',
      defaultMessage: 'Learn more about Multifactor authentication',
    })

    return (
      <PrivacySensitiveContainer>
        <EuiForm className='device-authentication'>
          <EuiDescribedFormGroup
            ratio='third'
            title={
              <h3>
                <FormattedMessage
                  id='user-settings-profile-mfa'
                  defaultMessage='Multifactor authentication'
                />
              </h3>
            }
            titleSize='xs'
            description={
              <Fragment>
                <FormattedMessage
                  id='user-settings-profile-help-text-mfa'
                  defaultMessage={`Make your account more secure with multifactor authentication. When logging in to your account, you'll be asked for a verification code using your preferred method to make sure it's you. {docLink}`}
                  values={{
                    docLink: (
                      <DocLink link='mfaDocLink' aria-label={learnMoreAriaLabel}>
                        <FormattedMessage
                          id='device-authentication.link'
                          defaultMessage='Learn more'
                        />
                      </DocLink>
                    ),
                  }}
                />

                <EuiSpacer />

                {this.renderSwitch()}

                {hasEnableDisableRequestError && (
                  <Fragment>
                    <EuiSpacer size='xl' />
                    <EuiCallOut
                      className='user-settings-profile-mfa-toggle-error'
                      title={
                        disableMfaDeviceRequest.error ? (
                          <FormattedMessage
                            id='user-settings-profile-mfa-disable-error-title'
                            defaultMessage='Error disabling authenticator app'
                          />
                        ) : (
                          <FormattedMessage
                            id='user-settings-profile-mfa-enabled-error-title'
                            defaultMessage='Error enabling authenticator app'
                          />
                        )
                      }
                      color='danger'
                      iconType='alert'
                    >
                      <FormattedMessage
                        id='user-settings-profile-mfa-toggle-switch-error-description'
                        defaultMessage='Something went wrong. {tryAgain}'
                        values={{
                          tryAgain: (
                            <EuiButtonEmpty onClick={this.toggleMfa} size='s'>
                              <FormattedMessage
                                id='user-settings-profile-mfa-enabled-error-try-again'
                                defaultMessage='Try again'
                              />
                            </EuiButtonEmpty>
                          ),
                        }}
                      />
                    </EuiCallOut>
                  </Fragment>
                )}
              </Fragment>
            }
          >
            <Fragment>
              <EuiFormRow>
                {this.props.flags?.mfaEnforced ? (
                  <DeviceOption type='GOOGLE' />
                ) : (
                  <MfaProvider
                    device={this.getDevice('GOOGLE')}
                    toggleMfa={this.toggleMfa}
                    render={(props: RenderProps) => (
                      <GoogleAuthenticator canRemove={this.canRemoveMfaDevice()} {...props} />
                    )}
                  />
                )}
              </EuiFormRow>

              {
                // We don't want to show the SMS enrollment if there is no active SMS device yet,
                // because we want users to move away from SMS MFA, except if the feature flag in on
                // but that's only for testing purposes, to simulate existing users that have SMS enabled.
                (this.getDevice('SMS').isActive || this.props.flags?.smsMfa) && (
                  <Fragment>
                    <EuiSpacer size='l' />

                    <EuiFormRow>
                      <MfaProvider
                        device={this.getDevice('SMS')}
                        toggleMfa={this.toggleMfa}
                        render={(props: RenderProps) => (
                          <TextMessageAuthentication
                            canRemove={this.canRemoveMfaDevice()}
                            {...props}
                          />
                        )}
                      />
                    </EuiFormRow>
                  </Fragment>
                )
              }

              <EuiSpacer size='l' />

              <EuiFormRow>
                {this.props.flags?.emailMfa ? (
                  <DeviceOption type='EMAIL' />
                ) : (
                  <ComingSoonDeviceAuthentication
                    title={
                      <FormattedMessage id='authenticator.email.title' defaultMessage='Email' />
                    }
                    description={
                      <FormattedMessage
                        id='authenticator.email.description'
                        defaultMessage='Receive a verification code on your email'
                      />
                    }
                  />
                )}
              </EuiFormRow>

              <EuiSpacer size='l' />

              <EuiFormRow>
                <ComingSoonDeviceAuthentication
                  title={
                    <FormattedMessage
                      id='authenticator.biometric.title'
                      defaultMessage='Security key or biometric authenticator'
                    />
                  }
                  description={
                    <FormattedMessage
                      id='authenticator.biometric.description'
                      defaultMessage='Enroll a hardware security key, or add a biometric authenticator such as Apple Touch ID or Face ID, or Windows Hello'
                    />
                  }
                />
              </EuiFormRow>
            </Fragment>
          </EuiDescribedFormGroup>
        </EuiForm>
      </PrivacySensitiveContainer>
    )
  }

  renderSwitch = (): ReactNode => {
    const { mfaEnabled, mfaDevices, disableMfaDeviceRequest, enableMfaDeviceRequest, flags } =
      this.props

    // When MFA is enforced and enabled, users should not be able to disable it.
    if (flags?.mfaEnforced && mfaEnabled) {
      return null
    }

    if (disableMfaDeviceRequest.inProgress || enableMfaDeviceRequest.inProgress) {
      return <EuiLoadingSpinner />
    }

    const hasAtLeastOneActiveDevice = some(mfaDevices, `isActive`)
    let switchLabel = <span>{mfaEnabled ? enabledLabel : disabledLabel}</span>

    if (!hasAtLeastOneActiveDevice && !mfaEnabled) {
      switchLabel = enableForbidden
    }

    return (
      <EuiSwitch
        className='user-settings-profile-toggle-mfa'
        label={switchLabel}
        checked={mfaEnabled || false}
        disabled={!hasAtLeastOneActiveDevice}
        onChange={this.toggleMfa}
      />
    )
  }

  renderMfaForm = () => {
    const {
      mfaDevices,
      mfaEnabled,
      resetVerifySaasCurrentUserMfaFactorRequest,
      verifySaasCurrentUserMfaFactorRequest,
    } = this.props

    if (mfaDevices && mfaEnabled) {
      const mfa = {
        mfa_required: mfaEnabled,
        mfa_devices: mfaDevices as MfaDeviceState[],
      }

      return (
        <EuiModal
          onClose={() => this.setState({ isMfaFormOpen: false })}
          style={{ padding: 10, width: 450 }}
        >
          <EuiModalBody>
            <EuiTitle>
              <h3>
                <FormattedMessage
                  id='user-settings-profile-mfa-form-title'
                  defaultMessage='Multifactor authentication'
                />
              </h3>
            </EuiTitle>
            <EuiSpacer size='m' />
            <PrivacySensitiveContainer>
              <MfaForm
                mfa={mfa}
                sendMfaChallenge={this.sendMfaChallenge}
                submitMfaResponseRequest={verifySaasCurrentUserMfaFactorRequest}
                resetSubmitMfaResponseRequest={resetVerifySaasCurrentUserMfaFactorRequest}
                onSubmit={this.verifySaasCurrentUserMfaFactor}
                onCancel={() => {
                  this.setState({ isMfaFormOpen: false })
                }}
              />
            </PrivacySensitiveContainer>
          </EuiModalBody>
        </EuiModal>
      )
    }

    return null
  }

  getDevice(deviceType: DeviceType): MfaDevice {
    const { mfaDevices } = this.props
    return (
      find(mfaDevices, { device_type: deviceType }) || {
        device_id: ``,
        device_type: deviceType,
        status: `NOT_SETUP`,
        isActive: false,
      }
    )
  }

  canRemoveMfaDevice = (): boolean => {
    const { mfaEnabled, mfaDevices, flags } = this.props

    // When MFA is enforced, we can always let them remove devices because the API will
    // throw an error saying they should have at least 1 active device.
    if (flags?.mfaEnforced) {
      return true
    }

    return !mfaEnabled || filter(mfaDevices, `isActive`).length > 1
  }

  toggleMfa = () => {
    if (this.props.mfaEnabled) {
      //before disabling MFA show the authenticator challenge window
      this.setState({ isMfaFormOpen: true })
    } else {
      this.props.enableMfa()
    }
  }

  sendMfaChallenge = (device_id: string) => {
    const { challengeSaasCurrentUserMfaFactor } = this.props
    return challengeSaasCurrentUserMfaFactor({
      device_id,
    })
  }

  verifySaasCurrentUserMfaFactor = ({ pass_code, device_id }) => {
    const { verifySaasCurrentUserMfaFactor, disableMfa } = this.props

    verifySaasCurrentUserMfaFactor({
      device_id,
      pass_code,
    }).then(() => {
      this.setState({ isMfaFormOpen: false })
      disableMfa()
    })
  }
}

const ComingSoonDeviceAuthentication = ({
  title,
  description,
}: {
  title: ReactNode
  description: ReactNode
}) => (
  <EuiFlexGroup justifyContent='center'>
    <ComingSoonBadge />

    <EuiPanel className='user-settings-profile-card' paddingSize='l' hasBorder={true}>
      <EuiFlexGroup gutterSize='m' responsive={false}>
        <EuiFlexItem style={{ opacity: 0.5 }}>
          <EuiFlexGroup direction='column' gutterSize='s' responsive={false}>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent='spaceEvenly' alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <EuiTitle size='s' className='user-settings-profile-card-title'>
                    <h3>{title}</h3>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size='s'>{description}</EuiText>

              <EuiSpacer size='s' />
            </EuiFlexItem>

            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton size='s' disabled={true}>
                  <FormattedMessage id='authenticator.set-up' defaultMessage='Set up' />
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  </EuiFlexGroup>
)

const ComingSoonBadge = () => {
  const { formatMessage } = useIntl()

  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translateY(-50%)',
      }}
    >
      <EuiBetaBadge
        label={formatMessage({
          id: 'authenticator.comming-soon',
          defaultMessage: 'Coming soon',
        })}
      />
    </div>
  )
}

export default injectIntl(withLDConsumer()(DeviceAuthentication))
