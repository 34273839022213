/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  domainClaimDeleteUrl,
  domainClaimGenerateVerificationCodeUrl,
  domainClaimGetDomainClaimsUrl,
  domainClaimVerifyDomainUrl,
  getOrganizationIdpUrl,
  setupOrganizationIdpUrl,
  getRoleMappingsUrl,
  updateRoleMappingsUrl,
  organizationSsoFindIdpUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import type {
  GetRoleMappingsResponse,
  RoleMapping,
  FindIdpResults,
} from '@modules/cloud-api/v1/types'

import type {
  DomainsResponse,
  DomainVerificationCodeResponse,
  IdpConfiguration,
  IdpResponse,
} from './types'

export const getDomainClaims: (organizationId: string) => Promise<DomainsResponse> = (
  organizationId,
) =>
  fetchAsJson<DomainsResponse>(domainClaimGetDomainClaimsUrl({ organizationId }), {
    method: 'get',
  })

export const generateDomainVerificationCode: (
  organizationId: string,
  domain: string,
) => Promise<DomainVerificationCodeResponse> = (organizationId, domain) =>
  fetchAsJson<DomainVerificationCodeResponse>(
    domainClaimGenerateVerificationCodeUrl({ organizationId }),
    {
      method: 'post',
      body: JSON.stringify({ domain_claim_request: domain }),
    },
  )

export const verifyDomainClaim: (organizationId: string, domain: string) => Promise<void> = (
  organizationId,
  domain,
) =>
  fetchAsJson<void>(domainClaimVerifyDomainUrl({ organizationId }), {
    method: 'post',
    body: JSON.stringify({ domain_claim_request: domain }),
  })

export const deleteDomainClaim: (organizationId: string, domain: string) => Promise<void> = (
  organizationId,
  domain,
) =>
  fetchAsJson<void>(domainClaimDeleteUrl({ organizationId }), {
    method: 'delete',
    body: JSON.stringify({ domain_claim_request: domain }),
  })

export const getOrganizationIdp: (organizationId: string) => Promise<IdpResponse> = (
  organizationId,
) =>
  fetchAsJson<IdpResponse>(getOrganizationIdpUrl({ organizationId }), {
    method: 'get',
  })

export const updateOrganizationIdp: ({
  organizationId,
  idpConfiguration,
}: {
  organizationId: string
  idpConfiguration: IdpConfiguration
}) => Promise<IdpResponse> = ({ organizationId, idpConfiguration }) =>
  fetchAsJson<IdpResponse>(setupOrganizationIdpUrl({ organizationId }), {
    method: 'put',
    body: JSON.stringify(idpConfiguration),
  })

export const getRoleMappings: (organizationId: string) => Promise<GetRoleMappingsResponse> = (
  organizationId,
) =>
  fetchAsJson<GetRoleMappingsResponse>(getRoleMappingsUrl({ organizationId }), {
    method: 'get',
  })

export const updateRoleMappings = async ({
  organizationId,
  mappings,
}: {
  organizationId: string
  mappings: RoleMapping[]
}): Promise<void> =>
  fetchAsJson<void>(updateRoleMappingsUrl({ organizationId }), {
    method: 'put',
    body: JSON.stringify({ mappings }),
  })
export const getOrganizationSsoFindIdp: (email: string) => Promise<FindIdpResults> = (email) =>
  fetchAsJson<FindIdpResults>(organizationSsoFindIdpUrl(), {
    method: 'post',
    body: JSON.stringify({ email }),
  })
