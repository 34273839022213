/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { useIntl } from 'react-intl'
import { noop } from 'lodash'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiInMemoryTable, EuiText, useEuiTheme } from '@elastic/eui'

import { useUpdateRoleMappings } from '@modules/security-idp-lib/hooks'
import type { RoleAssignments, RoleMapping } from '@modules/cloud-api/v1/types'
import { addToast } from '@modules/cui/Toasts'

import RoleBadges from '@/components/Organization/RoleBadges'

import { messages } from './messages'
import ConfirmRemovingRoleMappingModal from './ConfirmRemovingRoleMappingModal'

import type { AllProps } from './types'

const sorting = {
  sort: {
    field: 'name',
    direction: 'asc',
  } as const,
}

const RoleMappingTable = ({
  organizationId,
  isLoading,
  roleMappings = [],
  hasDeleteRoleMappingPermission,
  hasUpdateRoleMappingPermission,
}: AllProps) => {
  const { formatMessage } = useIntl()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false)
  const [selectedRoleMapping, setSelectedRoleMapping] = React.useState<RoleMapping | null>(null)
  const { euiTheme } = useEuiTheme()
  const { mutate: updateRoleMappings, isLoading: isUpdatingRoleMapping } = useUpdateRoleMappings()

  const onClickRemoveRoleMapping = (roleMapping: RoleMapping) => {
    setSelectedRoleMapping(roleMapping)
    setIsConfirmationModalOpen(true)
  }

  const onRemoveRoleMapping = () => {
    if (!organizationId || !selectedRoleMapping) {
      return
    }

    const removedMappingName = selectedRoleMapping.name
    const updatedRoleMappings = roleMappings.filter(
      (mapping) => mapping.name !== removedMappingName,
    )
    updateRoleMappings(
      { organizationId, mappings: updatedRoleMappings },
      {
        onSuccess: () => {
          addToast({
            family: 'organization.security.role-mappings-table.remove',
            color: 'success',
            iconType: 'check',
            title: formatMessage(messages.toastRemoveRoleMappingSuccess, {
              roleMappingName: removedMappingName,
            }),
          })
        },
        onError: () => {
          addToast({
            family: 'organization.security.role-mappings-table.remove',
            color: 'danger',
            iconType: 'alert',
            title: formatMessage(messages.toastRemoveRoleMappingError, {
              roleMappingName: removedMappingName,
            }),
          })
        },
        onSettled: () => setIsConfirmationModalOpen(false),
      },
    )
  }

  const columns: Array<EuiBasicTableColumn<RoleMapping>> = [
    {
      field: 'name',
      sortable: true,
      name: formatMessage(messages.nameColumn),
      render: (name: string) => (
        <EuiText size='s' color={euiTheme.colors.primaryText}>
          {name}
        </EuiText>
      ),
    },
    {
      field: 'role_assignments',
      name: formatMessage(messages.rolesColumn),
      render: (role_assignments: RoleAssignments) => (
        <RoleBadges roleAssignments={role_assignments} />
      ),
    },
    {
      name: formatMessage(messages.actionsColumn),
      align: 'right',
      actions: [
        {
          name: formatMessage(messages.editRoleMapping),
          description: formatMessage(messages.editRoleMapping),
          icon: 'pencil',
          color: 'primary',
          type: 'icon',
          isPrimary: true,
          onClick: () => noop,
          enabled: () => hasUpdateRoleMappingPermission,
          // available: hasUpdateRoleMappingPermission,
        },
        {
          name: formatMessage(messages.removeRoleMapping),
          description: formatMessage(messages.removeRoleMapping),
          icon: 'trash',
          color: 'danger',
          type: 'icon',
          onClick: onClickRemoveRoleMapping,
          enabled: () => hasDeleteRoleMappingPermission && hasUpdateRoleMappingPermission,
        },
      ],
    },
  ]

  return (
    <React.Fragment>
      <EuiInMemoryTable
        loading={isLoading}
        message={isLoading && formatMessage(messages.loadingRoleMappings)}
        columns={columns}
        items={roleMappings}
        sorting={sorting}
      />
      {isConfirmationModalOpen && (
        <ConfirmRemovingRoleMappingModal
          roleMappingName={selectedRoleMapping?.name ?? ''}
          isLoading={isUpdatingRoleMapping}
          onRemoveRoleMapping={onRemoveRoleMapping}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </React.Fragment>
  )
}

export default RoleMappingTable
