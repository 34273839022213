/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'discovery-questions.title',
    defaultMessage: 'Lastly, where would you like your data stored?',
  },
  subtitle: {
    id: 'discovery-questions.subtitle',
    defaultMessage: "We'll host your Elastic Cloud data in your preferred region.",
  },
  loading: { id: 'discovery-questions.loading', defaultMessage: 'Loading...' },
  launch: { id: 'discovery-questions.launch', defaultMessage: 'Launch' },
  continue: { id: 'discovery-questions.continue', defaultMessage: 'Continue' },
  cloudProvider: { id: 'discovery-questions.provider', defaultMessage: 'Provider' },
  region: { id: 'discovery-questions.region', defaultMessage: 'Region' },
  retry: { id: 'discovery-questions.retry', defaultMessage: 'Retry' },
  retryLong: {
    id: 'discovery-questions.retry-long',
    defaultMessage: 'Retry-fetching data from the server',
  },
  error: {
    id: 'discovery-questions.error',
    defaultMessage: 'An error occurred while fetching data. Please try again.',
  },
})
