/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiPanel,
  EuiHorizontalRule,
  EuiTitle,
  EuiCallOut,
} from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import Header from '@modules/cui/Header'

import EnableDiskNotificationsButton from '@/components/Organization/OrganizationOverview/EnableDiskNotificationsButton'
import { accountContactsCrumbs } from '@/lib/crumbBuilder'
import { replaceIn } from '@/lib/immutability-helpers'

import EmailDisplay from './EmailDisplay'
import EmailForm from './EmailForm'
import PasswordDisplay from './PasswordDisplay'
import PasswordForm from './PasswordForm'
import ContactForm from './ContactForm'
import Outcome from './Outcome'

import type { Props } from '.'

import './accountDetails.scss'

type State = {
  editing: string | null
  emailUpdateToastShown: boolean
}

const toastText = {
  emailDidChange: {
    color: 'success',
    title: (
      <FormattedMessage
        id='uc.accountDetails.emailForm.email-changed-notification'
        defaultMessage='Email change confirmed'
      />
    ),
  },
}

class AccountDetails extends Component<Props, State> {
  state: State = {
    editing: null,
    emailUpdateToastShown: false,
  }

  componentDidMount() {
    const { fetchOrganizationIfNeeded, organization, accountDetails } = this.props

    if (!organization && accountDetails && accountDetails.organization_id) {
      return fetchOrganizationIfNeeded({ organizationId: accountDetails.organization_id })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { updateAccountEmailRequest, updateAccountPasswordRequest } = this.props

    const didUpdateEmail =
      !prevProps.updateAccountEmailRequest.isDone && updateAccountEmailRequest.isDone

    const didUpdatePassword =
      !prevProps.updateAccountPasswordRequest.isDone && updateAccountPasswordRequest.isDone

    if (didUpdateEmail || didUpdatePassword) {
      this.stopEditing()
    }
  }

  componentWillUnmount() {
    this.props.resetUpdateAccountEmailRequest()
    this.props.resetUpdateAccountPasswordRequest()

    if (this.props.ui.emailDidChange) {
      this.props.clearEmailChangeConfirmation()
    }
  }

  render() {
    const { accountDetails, isPortalFeatureEnabled, isLaunchDarklyActivated, organization } =
      this.props

    const billingContacts = organization?.billing_contacts ?? []
    const operationalContacts = organization?.operational_contacts ?? []

    return (
      <PrivacySensitiveContainer>
        <Fragment>
          <EuiTitle size='s'>
            <h1>
              <FormattedMessage id='uc.accountDetails.options-title' defaultMessage='Options' />
            </h1>
          </EuiTitle>
          <EuiSpacer size='s' />
          {this.renderEnableDiskNotificationButton()}

          <EuiHorizontalRule />
        </Fragment>

        {!isPortalFeatureEnabled && (
          <Header
            name={
              <FormattedMessage id='uc.accountDetails.heading' defaultMessage='Account profile' />
            }
            breadcrumbs={accountContactsCrumbs()}
          />
        )}
        <Outcome {...this.props} />

        {!isPortalFeatureEnabled && (
          <Fragment>
            <EuiFormLabel data-test-id='uc-accountDetails-email-label'>
              <FormattedMessage
                id='uc.accountDetails.emailLabel'
                defaultMessage='Primary email address'
              />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            {this.renderEmail()}

            {this.renderEmailUpdateCallout()}

            <EuiSpacer size='m' />

            <EuiFormLabel data-test-id='uc-accountDetails-password-label'>
              <FormattedMessage id='uc.accountDetails.passwordLabel' defaultMessage='Password' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            {this.renderPassword()}
            <EuiSpacer size='l' />
          </Fragment>
        )}

        <ContactForm
          operationalContacts={operationalContacts}
          billingContacts={billingContacts}
          onSave={this.saveContacts}
          organizationId={accountDetails?.organization_id}
          isLaunchDarklyActivated={isLaunchDarklyActivated}
        />
      </PrivacySensitiveContainer>
    )
  }

  renderEnableDiskNotificationButton() {
    const { organization } = this.props

    if (organization) {
      // disk notifications are enabled by default, and this field only gets set once the user explicitly makes a change
      const diskNotificationsEnabled = organization.default_disk_usage_alerts_enabled ?? true

      return (
        <EnableDiskNotificationsButton
          organizationId={organization.id}
          enabled={diskNotificationsEnabled}
        />
      )
    }

    return null
  }

  renderEmail() {
    const { editing } = this.state
    const { accountDetails, updateAccountEmail, updateAccountEmailRequest } = this.props

    if (!accountDetails) {
      return null
    }

    const email = accountDetails.email

    if (editing === `email`) {
      return (
        <Fragment>
          <EuiSpacer size='s' />

          <EuiFlexGroup
            data-test-id='uc-accountDetails-email-display'
            className='accountDetails-emailFormContainer'
          >
            <EuiFlexItem>
              <EuiPanel>
                <EmailForm
                  email={email}
                  emailUpdateRequest={updateAccountEmailRequest}
                  onSave={updateAccountEmail}
                  onCancel={this.stopEditing}
                />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
        </Fragment>
      )
    }

    return <EmailDisplay email={email} onEdit={this.startEditingEmail} />
  }

  renderPassword() {
    const { editing } = this.state
    const { updateAccountPasswordRequest } = this.props

    if (editing === `password`) {
      return (
        <Fragment>
          <EuiSpacer size='s' />

          <EuiFlexGroup
            data-test-id='uc-accountDetails-password-display'
            className='accountDetails-passwordFormContainer'
          >
            <EuiFlexItem>
              <EuiPanel>
                <PasswordForm
                  error={updateAccountPasswordRequest.error}
                  onSave={this.savePassword}
                  onCancel={this.stopEditing}
                />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      )
    }

    return <PasswordDisplay onEdit={this.startEditingPassword} />
  }

  renderEmailUpdateCallout() {
    const { confirmEmailChangeRequest, updateAccountEmailRequest } = this.props
    const { editing, emailUpdateToastShown } = this.state
    const newEmail = updateAccountEmailRequest.meta.email

    if (updateAccountEmailRequest.isDone) {
      return (
        <Fragment>
          <EuiSpacer size='s' />
          <EuiCallOut
            data-test-id='confirm-email-change-notice'
            title={
              <FormattedMessage
                id='uc.accountDetails.emailForm.confirm-email-change-notice-title'
                defaultMessage='Validate your new email address'
              />
            }
          >
            <FormattedMessage
              id='uc.accountDetails.emailForm.confirm-email-change-notice'
              defaultMessage='We sent you a confirmation link to {newEmail}. Click it to update your email. This will replace your current credentials with the new email address'
              values={{
                newEmail: <strong>{newEmail}</strong>,
              }}
            />
          </EuiCallOut>
        </Fragment>
      )
    }

    if (confirmEmailChangeRequest.isDone && !editing && !emailUpdateToastShown) {
      addToast({
        ...toastText.emailDidChange,
      })
      this.setState({ emailUpdateToastShown: true })
    }

    return null
  }

  savePassword = ({ oldPassword, newPassword }) => {
    if (!this.props.accountDetails) {
      // sanity as this can't happen in reality
      return
    }

    this.props.updateAccountPassword({ oldPassword, newPassword })
  }

  saveContacts = (type, contacts) => {
    if (!this.props.accountDetails) {
      // sanity as this can't happen in reality
      return
    }

    const accountDetails = replaceIn(
      this.props.accountDetails,
      [`data`, `notifications`, type],
      contacts,
    )

    if (!accountDetails.organization_id) {
      return
    }

    return this.props.updateOrganization({
      organizationId: accountDetails.organization_id,
      organization: { [`${type}_contacts`]: contacts },
    })
  }

  startEditingEmail = () => {
    this.props.resetUpdateAccountEmailRequest()
    this.setState({ editing: `email`, emailUpdateToastShown: false })
  }

  startEditingPassword = () => {
    this.props.resetUpdateAccountPasswordRequest()
    this.setState({ editing: `password` })
  }

  stopEditing = () => {
    this.setState({ editing: null })
  }
}

export default AccountDetails
