/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentUpdateRequest, InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'

import { getVersion, getSliderPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import {
  getSliderPlan,
  getFirstSliderCluster,
} from '@/lib/stackDeployments/selectors/stackDeployment'
import { getFirstEsId } from '@/lib/stackDeployments/selectors/clusters'
import { getSupportedSliderInstanceTypesForVersion } from '@/lib/sliders/support'
import { getAutoscalingStatus } from '@/lib/stackDeployments/selectors/autoscaling'

import { diffPlan } from '../../../../lib/stackDeployments/planDiffs'
import { formatDifferences } from '../formatters'
import { considerNoOpProductChanges, getClusterOperations } from '../explainChangesHelpers'

import type { ExplainedChange } from '../types'

export default function explainChanges({
  newDeployment,
  oldDeployment,
  instanceConfigurations,
  pruneOrphans,
}: {
  newDeployment: DeploymentUpdateRequest
  oldDeployment: StackDeployment
  instanceConfigurations: InstanceConfiguration[]
  pruneOrphans?: boolean
}): ExplainedChange[] {
  const allChanges: ExplainedChange[] = []
  const version = getVersion({ deployment: oldDeployment || newDeployment })

  getSupportedSliderInstanceTypesForVersion(version).forEach((sliderInstanceType) => {
    const resource = getFirstSliderCluster({ deployment: newDeployment, sliderInstanceType })

    if (!resource) {
      return // irrelevant
    }

    const oldPlan = getSliderPlanFromGet({ deployment: oldDeployment, sliderInstanceType })
    const newPlan = getSliderPlan({ deployment: newDeployment, sliderInstanceType })

    if (!newPlan && !oldPlan) {
      return // irrelevant
    }

    const differences = diffPlan({
      instanceConfigurations,
      sliderInstanceType,
      pruneOrphans: pruneOrphans || false,
      current: oldPlan,
      next: newPlan,
      currentDeployment: oldDeployment,
      nextDeployment: newDeployment,
      autoscalingStatus: getAutoscalingStatus({
        deployment: newDeployment,
      }),
    })

    const changes: ExplainedChange[] = [
      ...formatDifferences({ differences, isPastHistory: false }),
      ...getClusterOperations({
        type: sliderInstanceType,
        plan: newPlan,
        source: undefined,
        regionId: resource.region,
        esResourceId: getFirstEsId({ deployment: oldDeployment }),
        isPastHistory: false,
      }),
    ]

    considerNoOpProductChanges({
      type: sliderInstanceType,
      changes,
      oldPlan,
      newPlan,
    })

    allChanges.push(...changes)
  })

  return allChanges
}
