/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { noop } from 'lodash'

import {
  EuiButton,
  EuiButtonIcon,
  EuiFieldText,
  EuiFormRow,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'

import type { IntlShape } from 'react-intl'
import type { ReactNode } from 'react'

const messages = defineMessages({
  addNewEmailLabel: {
    id: `uc.accountDetails.contactForm.label`,
    defaultMessage: `Add new email`,
  },
  addNewEmailPlaceholder: {
    id: `uc.accountDetails.contactForm.placeholder`,
    defaultMessage: `e.g. name@domain.com`,
  },
})

type Props = {
  intl: IntlShape
  contacts: string[]
  onAdd: (emailAddress: string) => Promise<any>
  onRemove: (emailAddress: string) => void
  error: ReactNode
}

type State = {
  toBeAdded: string
  adding: boolean
}

class ContactTable extends Component<Props, State> {
  inputRef: HTMLInputElement | null = null

  state: State = {
    toBeAdded: ``,
    adding: false,
  }

  render() {
    const { adding, toBeAdded } = this.state
    const {
      intl: { formatMessage },
      contacts,
      onRemove,
      error,
    } = this.props

    const disabled = adding || toBeAdded.length === 0

    const columns = [
      {
        label: <FormattedMessage id='deployment-activity-table.contact' defaultMessage='Contact' />,
        render: (contact) => contact,
      },
      {
        label: <FormattedMessage id='deployment-activity-table.actions' defaultMessage='Actions' />,
        render: (contact) => (
          <EuiButtonIcon
            aria-label={formatMessage(
              {
                id: `uc.accountDetails.removeContact.label`,
                defaultMessage: `Remove contact {email}`,
              },
              {
                email: contact,
              },
            )}
            size='s'
            color='danger'
            iconType='cross'
            onClick={() => onRemove(contact)}
          />
        ),
      },
    ]

    return (
      <Fragment>
        <CuiTable
          className='contact-table'
          rows={contacts}
          columns={columns}
          hasFooterRow={false}
        />
        <EuiSpacer size='xs' />
        <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize={'xs'}>
          <EuiFlexItem grow={true}>
            <EuiFormRow
              isInvalid={!!error}
              error={error}
              label={formatMessage(messages.addNewEmailLabel)}
            >
              <EuiFieldText
                inputRef={(r) => {
                  this.inputRef = r
                }}
                icon='email'
                type='email'
                isLoading={adding}
                isInvalid={!!error}
                value={this.state.toBeAdded}
                onChange={(e) => this.setState({ toBeAdded: (e.target as HTMLInputElement).value })}
                placeholder={formatMessage(messages.addNewEmailPlaceholder)}
                append={
                  <EuiButton onClick={this.add} disabled={disabled}>
                    <FormattedMessage
                      id='uc.accountDetails.contactForm.addButton'
                      defaultMessage='Add'
                    />
                  </EuiButton>
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
      </Fragment>
    )
  }

  add = () => {
    this.setState({ adding: true })
    return this.props
      .onAdd(this.state.toBeAdded)
      .then(() => this.setState({ toBeAdded: `` }))
      .catch(noop)
      .finally(() => {
        this.setState({ adding: false })

        if (this.inputRef) {
          this.inputRef.focus()
        }
      })
  }
}

export default injectIntl(ContactTable)
