/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProductLineItem, Quantity } from '@modules/billing-api/v1/types'

import emptyTableIcon from '@/files/billing_usage_datatable.svg'
import emptyBarChartIcon from '@/files/billing_usage_bar_chart.svg'
import emptyLineChartIcon from '@/files/billing_usage_line_chart.svg'

export interface DeploymentInstanceWithAggregatedCosts extends TotalDeploymentCostsPerDimension {
  id: string
  name: string
}

export interface TotalDeploymentCostsPerDimension {
  capacity: number
  dataTransfer: number
  storage: number
  synthetics: number
  total_ecu: number
}

export interface TotalProjectCollapsedCosts {
  total_ecu: number
  type: ProjectSolution[] | ProjectSolution
}

export type ProjectSolution = 'security' | 'elasticsearch' | 'observability'

export enum IconType {
  TABLE = 'table',
  LINE_CHART = 'line_chart',
  BAR_CHART = 'bar_chart',
}

export const mapTypeToIcon = {
  [IconType.TABLE]: emptyTableIcon,
  [IconType.BAR_CHART]: emptyBarChartIcon,
  [IconType.LINE_CHART]: emptyLineChartIcon,
}

export interface ConsumerProps {
  organizationId: string
  setInstanceName: (instanceName?: string) => void
}

export type AllProps = ConsumerProps

export interface PLI extends Omit<ProductLineItem, 'quantities' | 'quantity'> {
  isTierRootPLI?: boolean
  isNonTierRootPLI?: boolean
  isTier?: boolean
  from?: string
  to?: string
  previewPrice?: number
  quantity?: Quantity
}
