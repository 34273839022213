/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import moment from 'moment'
import { Fragment } from 'react'

import { EuiBadge, EuiBasicTable } from '@elastic/eui'
import type { EuiBasicTableColumn } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { components } from '@modules/billing-api/v1/types'
import { useGetBasePricesV1 } from '@modules/billing-lib/hooks'

import QuantityTableInfo from '../components/QuantityTableInfo'
import PromotionalPeriodBadge from '../components/PreviewCost/PromotionalPeriodBadge'
import PreviewCost from '../components/PreviewCost'
import { canPreviewPriceBeApplied } from '../components/PreviewCost/utils'

import { applyPreviewPrices, getBillingDimensionName, getProductDetailsItems } from './utils'

import type { PLI } from './types'

const tableCss = css({
  paddingLeft: 15,
  paddingRight: 30,
  '& tr:last-of-type td': { borderBottom: 'none ' },
})

interface Props {
  productLineItems: Array<components['schemas']['ProductLineItem']>
}

const ProductDetailsPanel = ({ productLineItems }: Props) => {
  const { data: base_prices = [] } = useGetBasePricesV1()

  const getLineItemBadge = (item: PLI) => {
    if (
      canPreviewPriceBeApplied({ previewPrice: item.previewPrice, currentPrice: item.rate.value })
    ) {
      return <PromotionalPeriodBadge />
    }

    if (item.to && item.from) {
      return (
        <EuiBadge color='hollow'>{`${moment(item.from).format('YYYY/MM/DD')} - ${moment(
          item.to,
        ).format('YYYY/MM/DD')}`}</EuiBadge>
      )
    }

    if (item.to) {
      return (
        <EuiBadge color='hollow'>
          <FormattedMessage
            id='product-details-panel.name.to-badge'
            defaultMessage='up until {date}'
            values={{ date: moment(item.to).format('MMM YYYY') }}
          />
        </EuiBadge>
      )
    }

    return null
  }

  const getTotalCost = (item: PLI) => {
    if (item.isTierRootPLI || !item.quantity) {
      return null
    }

    if (
      canPreviewPriceBeApplied({ previewPrice: item.previewPrice, currentPrice: item.rate.value })
    ) {
      return <PreviewCost previewPrice={item.previewPrice!} quantity={item.quantity.value} />
    }

    return (
      <CuiElasticConsumptionUnits value={item.total_ecu} unit='none' dp={4} withSymbol={false} />
    )
  }

  const getPrice = (item: PLI) => {
    if (item.isTierRootPLI) {
      return null
    }

    if (
      canPreviewPriceBeApplied({ previewPrice: item.previewPrice, currentPrice: item.rate.value })
    ) {
      return (
        <FormattedMessage
          id='billing-usage.preview_price'
          defaultMessage='{previewPrice} per {unit}'
          values={{
            previewPrice: item.previewPrice,
            unit: item.unit,
          }}
        />
      )
    }

    return item.rate.formatted_value
  }

  const columns: Array<EuiBasicTableColumn<PLI>> = [
    {
      name: <FormattedMessage id='product-details-panel.name' defaultMessage='Component' />,
      width: '43%',
      render: (item: PLI) => {
        if (item.isTier) {
          return <em css={css({ marginLeft: 12 })}>{item.name}</em>
        }

        return (
          <Fragment>
            <p css={css({ marginRight: 8 })}>{item.name}</p>
            {getLineItemBadge(item)}
          </Fragment>
        )
      },
    },
    {
      name: (
        <FormattedMessage
          id='product-details-panel.billing-dimension'
          defaultMessage='Billing dimension'
        />
      ),
      render: (item: PLI) => !item.isTierRootPLI && getBillingDimensionName(item.type),
    },
    {
      name: <FormattedMessage id='product-details-panel.quantity' defaultMessage='Quantity' />,
      render: (item: PLI) => {
        if (item.isTierRootPLI || !item.quantity) {
          return null
        }

        return (
          <QuantityTableInfo
            unit={item.unit}
            display_quantity={item.display_quantity}
            quantity={item.quantity.formatted_value}
          />
        )
      },
    },
    {
      name: (
        <FormattedMessage
          id='product-details-panel.item-price-name'
          defaultMessage='Price (in ECU)'
        />
      ),
      render: (item: PLI) => getPrice(item),
    },
    {
      name: <FormattedMessage id='product-details-panel.cost' defaultMessage='Cost (in ECU)' />,
      render: (item: PLI) => getTotalCost(item),
      align: 'right',
    },
  ]

  return (
    <EuiBasicTable
      items={applyPreviewPrices(getProductDetailsItems(productLineItems), base_prices)}
      columns={columns}
      css={tableCss}
    />
  )
}

export default ProductDetailsPanel
